<template>
    <div id='formWrap' class="form-wrap">
        <div class="box">
            <div class="title">{{$t('SecondContract.Buy')}} {{curCoin.name}}</div>
            <div class="amount-item">
                <div class="label">{{$t('SecondContract.OpeningQuantity')}} (USDT)</div>
                <el-input class="quantity-input" v-model="quantity1" type="number" :placeholder="$t('SecondContract.EnterOpeningQuantity')"></el-input>
            </div>
            <div class="opening-options">
                <div class="opening-quantity">
                    <div class="label">{{$t('SecondContract.SelectQuantity')}}</div>
                    <div class="list">
                        <div v-for="(item,index) in openList" 
                        :key="index"
                        :class="['item', amountCurIndex1 == index ? 'active' : '']"
                        @click="changeAmountCurIndex1(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="opening-time">
                    <div class="label">{{$t('SecondContract.OpeningTime')}}</div>
                    <div class="list">
                        <div v-for="(item,index) in timeList" 
                        :key="index"
                        :class="['item', timeCurIndex1 == index ? 'active' : '']"
                        @click="changeTimeCurIndex1(index)">
                            {{item.minute}}{{$t('SecondContract.minute')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-list">
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Balance')}}</div>
                    <div class="value">{{balance}}USDT</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Transfer')}}</div>
                    <div class="value">{{actualAmount1}}USDT</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                    <div class="value">{{curMinute1.service_fee_rate}}%</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Profitability')}}</div>
                    <div class="value">{{curMinute1.profit_rate}}%</div>
                </div>
            </div>
            <div class="buyup buy-btn" @click="buy(1)">{{$t('SecondContract.BuyUp')}}</div>
        </div>
        <div class="box">
            <div class="title">{{$t('SecondContract.Buy')}} {{curCoin.name}}</div>
            <div class="amount-item">
                <div class="label">{{$t('SecondContract.OpeningQuantity')}} (USDT)</div>
                <el-input class="quantity-input" v-model="quantity2" type="number" :placeholder="$t('SecondContract.EnterOpeningQuantity')"></el-input>
            </div>
            <div class="opening-options">
                <div class="opening-quantity">
                    <div class="label">{{$t('SecondContract.SelectQuantity')}}</div>
                    <div class="list">
                        <div v-for="(item,index) in openList" 
                        :key="index"
                        :class="['item', amountCurIndex2 == index ? 'active' : '']"
                        @click="changeAmountCurIndex2(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="opening-time">
                    <div class="label">{{$t('SecondContract.OpeningTime')}}</div>
                    <div class="list">
                        <div v-for="(item,index) in timeList" 
                        :key="index"
                        :class="['item', timeCurIndex2 == index ? 'active' : '']"
                        @click="changeTimeCurIndex2(index)">
                            {{item.minute}}{{$t('SecondContract.minute')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-list">
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Balance')}}</div>
                    <div class="value">{{balance}}USDT</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Transfer')}}</div>
                    <div class="value">{{actualAmount2}}USDT</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                    <div class="value">{{curMinute2.service_fee_rate}}%</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('SecondContract.Profitability')}}</div>
                    <div class="value">{{curMinute2.profit_rate}}%</div>
                </div>
            </div>
            <div class="buydown buy-btn" @click="buy(-1)">{{$t('SecondContract.BuyDown')}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                openList: [],
                amountCurIndex1: 0,
                amountCurIndex2: 0,
                timeList: [],
                timeCurIndex1: 0,
                timeCurIndex2: 0,
                quantity1: '',
                quantity2: '',
                curMinute1: { 
                    minute: 0,
                    profit_rate: 0,
                    service_fee_rate: 0
                },
                curMinute2: {
                    minute: 0,
                    profit_rate: 0,
                    service_fee_rate: 0
                },
                balance: 0
            }
        },
        created() {
            this.getBalance()
            this.getSetting()
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
            
            actualAmount1() {
                let quantity1 = this.quantity1 ? parseFloat(this.quantity1) : 0
                let service_fee = this.curMinute1.service_fee_rate ? parseFloat(this.curMinute1.service_fee_rate) / 100 : 0
                return (quantity1 * (1 - service_fee)).toFixed(2)
            },
            
            actualAmount2() {
                let quantity2 = this.quantity2 ? parseFloat(this.quantity2) : 0
                let service_fee = this.curMinute2.service_fee_rate ? parseFloat(this.curMinute2.service_fee_rate) / 100 : 0
                return (quantity2 * (1 - service_fee)).toFixed(2)
            }
        },
        methods: {
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
            
            getSetting() {
                this.$api.setting({
                    slug: 'second_conf,second_moneys'
                },300000).then(res => {
                    this.openList = res.data.second_moneys ? res.data.second_moneys.split(',') : []
                    this.quantity1 = this.openList[this.amountCurIndex1] || ''
                    this.quantity2 = this.openList[this.amountCurIndex2] || ''
                    this.timeList = res.data.second_conf ? JSON.parse(res.data.second_conf) : [],
                    this.curMinute1 = this.timeList[this.timeCurIndex1] || { minute: 0, profit_rate: 0, service_fee_rate: 0}
                    this.curMinute2 = this.timeList[this.timeCurIndex2] || { minute: 0, profit_rate: 0, service_fee_rate: 0}
                })
            },
            
            changeAmountCurIndex1(index) {
                this.amountCurIndex1 = index
                this.quantity1 = this.openList[this.amountCurIndex1]
            },
            
            changeAmountCurIndex2(index) {
                this.amountCurIndex2 = index
                this.quantity2 = this.openList[this.amountCurIndex2]
            },
            
            changeTimeCurIndex1(index) {
                this.timeCurIndex1 = index
                this.curMinute1 = this.timeList[this.timeCurIndex1]
            },
            
            changeTimeCurIndex2(index) {
                this.timeCurIndex2 = index
                this.curMinute2 = this.timeList[this.timeCurIndex2]
            },
            
            buy(direction) {
                this.$confirm(this.$t('Trade.BuyTips'), '', {
                    customClass: "prompt-box",
                    confirmButtonText: this.$t('Common.Confirm'),
                    cancelButtonText: this.$t('Common.Cancel'),
                    confirmButtonClass: 'prompt-confirm-btn',
                    cancelButtonClass: 'prompt-cancel-btn',
                    showClose: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    let loading = this.$loading.service({
                        target: '#formWrap',
                        text: this.$t('Common.Loading')
                    })
                    this.$api.secondOrderCreate({
                        product_id: this.curCoin.id,
                        direction,
                        minute: direction == 1 ? this.curMinute1.minute : this.curMinute2.minute,
                        money: direction == 1 ? this.quantity1 : this.quantity2
                    }).then(res => {
                        loading.close()
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 1500
                        })
                        this.getBalance()
                        this.$bus.$emit('updateSecondOrder')
                    }).catch(err => {
                        loading.close()
                    })
                }).catch(() => {
                    
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap {
        flex: auto;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 25px 0 32px;
        margin-top: 5px;
        background-color: #FFFFFF;
        
        .box {
            position: relative;
            width: 50%;
            box-sizing: border-box;
            padding: 0 20px;
            
            &:not(:last-child)::after {
                position: absolute;
                top: 0;
                right: 0;
                content: '';
                width: 1px;
                height: 480px;
                background-color: #EFEFEF;
            }
            
            .title {
                width: 100%;
                font-size: 19px;
                font-weight: bold;
                color: #000000;
                word-wrap: break-word;
            }
            
            .amount-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 52px;
                box-sizing: border-box;
                padding: 0 14px;
                border: 1px solid #E7E9F1;
                border-radius: 6px;
                margin-top: 20px;
            
                .label {
                    margin-right: 24px;
                    font-size: 13px;
                    color: #383942;
                    white-space: nowrap;
                }
            
                .num {
                    max-width: 100%;
                    font-size: 15px;
                    color: #000000;
                    word-break: break-all;
                }
            
                .quantity-input {
                    width: 100%;
            
                    /deep/ .el-input__inner {
                        height: 40px;
                        line-height: 40px;
                        padding: 0;
                        border: 0;
                        font-size: 15px;
                        color: #000000;
                        text-align: right;
                    }
                }
            }
            
            .opening-options {
                width: 100%;
                margin-top: 20px;
                
                .opening-quantity {
                    width: 100%;
                    margin-bottom: 20px;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 10px;
                        font-size: 13px;
                        color: #6D6F7E;
                        word-break: break-all;
                    }
                    
                    .list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                    
                        .item {
                            width: 72px;
                            height: 35px;
                            line-height: 35px;
                            box-sizing: border-box;
                            margin: 0 8px 8px 0;
                            border: 1px solid #5466EF;
                            border-radius: 4px;
                            font-size: 13px;
                            color: #5466EF;
                            text-align: center;
                            background-color: #FFFFFF;
                            cursor: pointer;
                        }
                    
                        .active {
                            color: #FFFFFF !important;
                            background-color: #5466EF !important;
                        }
                    }
                }
                
                .opening-time {
                    width: 100%;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 10px;
                        font-size: 13px;
                        color: #6D6F7E;
                        word-break: break-all;
                    }
                    
                    .list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                    
                        .item {
                            width: 78px;
                            height: 35px;
                            line-height: 35px;
                            box-sizing: border-box;
                            margin: 0 8px 8px 0;
                            border: 1px solid #5466EF;
                            border-radius: 4px;
                            font-size: 13px;
                            color: #5466EF;
                            text-align: center;
                            background-color: #FFFFFF;
                            cursor: pointer;
                        }
                    
                        .active {
                            color: #FFFFFF !important;
                            background-color: #5466EF !important;
                        }
                    }
                }
            }
            
            .info-list {
                width: 100%;
                margin-top: 20px;
                
                .info-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    cursor: default;
                    
                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                    
                    .label {
                        max-width: 48%;
                        font-size: 13px;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        max-width: 48%;
                        font-size: 14px;
                        color: #000000;
                        word-break: break-all;
                    }
                }
            }
            
            .buy-btn {
                width: 100%;
                height: 46px;
                line-height: 46px;
                box-sizing: border-box;
                margin-top: 40px;
                border-radius: 46px;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            
            .buyup {
                background-color: #01BD8B;
            }
            
            .buydown {
                background-color: #FB474E;
            }
        }
    }
</style>