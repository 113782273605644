<template>
    <div class="pc-container">
        <div class="login-wrap">
            <div class="left">
                <img class="login-img" src="@/assets/images/pc/login/login-img.png" />
                <div class="system-name">NeoDeal</div>
            </div>
            <div class="right">
                <div class="title">{{$t('Login.Login')}}</div>
                <div class="type">{{$t('Login.Type')}}</div>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="email">
                        <el-input v-model="form.email" :placeholder="$t('Login.Email')"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="form.password" show-password :placeholder="$t('Login.Password')"></el-input>
                    </el-form-item>
                </el-form>
                <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Login.Login')}}</el-button>
                <div class="other">
                    <div class="item" @click="to('/forget')">{{$t('Login.Forget')}}</div>
                    <div class="item register" @click="to('/register')">{{$t('Login.Register')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    export default {
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                rules: {
                    email: [
                        { required: true, message: this.$t('Login.EnterEmail'), trigger: 'blur' },
                        { type: 'email',  message:  this.$t('Login.EnterEmailError'), trigger: 'submit' }
                    ],
                    password: [
                        { required: true, message: this.$t('Login.EnterPassword'), trigger: 'blur' },
                        { min: 6, max: 20, message: this.$t('Login.EnterPasswordError'), trigger: 'blur' }
                    ]
                },
                loading: false
            }
        },
        computed: {
            redirect() {
                return this.$route.query.redirect || '/'
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            submit() {
                this.$refs.form.validate((valid) => {
                	// console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.login(this.form).then(res => {
                            this.$store.commit('setOnce',true)
                            this.$store.commit('setToken',res.data.token)
                            if (this.$ws.socket) {
                                this.$ws.socket = null
                            }
                            this.to(this.redirect)
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 80px 0;
        background: url(~@/assets/images/pc/login/login-bg.png) no-repeat;
        background-size: 100% 100%;
        
        .login-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 500px;
            
            .left {
                position: relative;
                height: 100%;
                
                .login-img {
                    display: block;
                    height: 100%;
                }
                
                .system-name {
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 40px;
                    font-weight: bold;
                    color: #5466EF;
                    white-space: nowrap;
                }
            }
            
            .right {
                width: 400px;
                height: 100%;
                box-sizing: border-box;
                padding: 28px 32px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 28px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .type {
                    width: 100%;
                    margin-bottom: 32px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-form-item {
                    margin-bottom: 24px;
                }
                
                /deep/ .el-input__inner {
                    height: 42px;
                    line-height: 42px;
                    padding: 0 18px;
                    border: 1px solid #D3D3D3;
                    font-size: 14px;
                    color: #000000;
                    background-color: #F5F5F5;
                }
                
                .submit-btn {
                    width: 100%;
                    height: 52px;
                    box-sizing: border-box;
                    margin: 72px 0 28px;
                    padding: 0;
                    border: 0;
                    border-radius: 4px;
                    font-size: 15px;
                    color: #FFFFFF;
                    text-align: center;
                    background-color: #5466EF;
                    cursor: pointer;
                }
                
                .other {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    
                    .item {
                        max-width: 48%;
                        font-size: 14px;
                        color: #798199;
                        word-wrap: break-word;
                        cursor: pointer;
                    }
                    
                    .register {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>
