<template>
    <div class="pc-container">
        <Nav :curIndex="2"></Nav>
        <div class="main">
            <div class="box1">
                <div class="title">{{$t('Assets.Withdraw')}}</div>
                <el-form ref="form" :model="form" :rules="rules">
                    <div class="info-wrap">
                        <div class="info-item">
                            <div class="label">{{$t('Assets.WithdrawalAmount')}}</div>
                            <div class="form-item">
                                <el-form-item prop="num">
                                    <el-input v-model="form.num" type="number" class="form-input" :placeholder="$t('Assets.Least') + ' ' + min"></el-input>
                                </el-form-item>
                                <div class="suffix">
                                    <div class="symbol">USDT</div>
                                    <div class="all" @click="form.num = balance">{{$t('Assets.All')}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="balance">{{$t('Assets.AvailableBalance')}}: {{balance}}USDT</div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.WithdrawalCurrency')}}</div>
                            <el-select v-model="form.to_user_credit_type" class="select-box" placeholder="">
                                <span slot="prefix">1{{form.to_user_credit_type}} = {{exchangeRate}}USDT</span>
                                <el-option v-for="(item,index) in withdrawCoins" :key="index" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.WithdrawalAddress')}}</div>
                            <el-form-item prop="address">
                                <el-input v-model="form.address" class="form-input" placeholder=""></el-input>
                            </el-form-item>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.Quantity')}}</div>
                            <div class="value">{{coinAmount}} {{form.to_user_credit_type}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.HandlingFee')}}</div>
                            <div class="value">{{fee}} {{form.to_user_credit_type}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.ReceiptAmount')}}</div>
                            <div class="value">{{actualAmount}} {{form.to_user_credit_type}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.TransactionPassword')}}</div>
                            <el-form-item v-if="userInfo.is_pay_pass" prop="pay_pass">
                                <el-input v-model="form.pay_pass" show-password class="form-input" placeholder=""></el-input>
                            </el-form-item>
                            <div v-else class="set-btn" @click="to('/account/ChangeTransactionPassword/0')">{{$t('Assets.SetTransactionPassword')}}</div>
                        </div>
                        <div v-if="userInfo.isgoogle" class="info-item">
                            <div class="label">{{$t('Assets.GoogleVerificationCode')}}</div>
                            <el-form-item prop="google_code">
                                <el-input v-model="form.google_code" type="number" class="form-input" placeholder=""></el-input>
                            </el-form-item>
                        </div>
                        <!-- <div v-else class="set-btn" @click="to('/account/GoogleAuth/0')">添加谷歌验证码</div> -->
                        <el-button :loading="loading" class="confirm-btn" @click="withdraw">{{$t('Common.Confirm')}}</el-button>
                    </div>
                </el-form>
            </div>
            <div class="box2">
                <div class="title">{{$t('Assets.WithdrawalsRecord')}}</div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="created_at" :label="$t('Assets.Time')">
                    </el-table-column>
                    <!-- <el-table-column label="账单类型">
                        <template slot-scope="scope">
                            <span>提现</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="to_user_credit_type" :label="$t('Assets.Currency')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.WithdrawalAmount')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.num)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.HandlingFee')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.commission)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.ReceiptAmount')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.channel_price)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Status')">
                        <template slot-scope="scope">
                            <div class="status-box">
                                <span class="status" :style="{color: curStatus(scope.row.status).color}">
                                    {{curStatus(scope.row.status).name}}
                                    {{(scope.row.status == -1 || scope.row.status == 3) && scope.row.reason ? ` (${scope.row.reason})` : ''}}
                                </span>
                                <el-button v-if="scope.row.status == 0 || scope.row.status == 3" :loading="btnLoading" class="cancel-btn" @click="cancel(scope.row.id)">{{$t('Common.Cancel')}}</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="recordList.length > 0" class="pagination-box">
                    <el-pagination @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="5"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="prev, pager, next, jumper"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Nav: () => import('./nav.vue')
        },
        data() {
            const checkNum = (rule, value, callback) => {
                if (!value) {
                    callback(new Error(this.$t('Assets.EnterWithdrawalAmount')))
                } else if(parseFloat(value) < this.min) {
                    callback(new Error(this.$t('Assets.Least') + ' ' + this.min))
                } else {
                    callback()
                }
            }
            return {
                form: {
                    num: '',
                    to_user_credit_type: 'BTC',
                    address: '',
                    pay_pass: '',
                    google_code: ''
                },
                rules: {
                    num: [{
                        validator: checkNum,
                        trigger: 'blur'
                    }],
                    address: [{
                        required: true,
                        message: this.$t('Assets.EnterWithdrawalAddress'),
                        trigger: 'blur'
                    }],
                    pay_pass: [{
                        required: true,
                        message: this.$t('Assets.EnterTransactionPassword'),
                        trigger: 'blur'
                    }],
                    google_code: [{
                        required: true,
                        message: this.$t('Assets.EnterGoogleVerificationCode'),
                        trigger: 'blur'
                    }]
                },
                min: 0,
                balance: 0,
                coinFee: {
                    BTC: 0,
                    ETH: 0,
                    USDT: 0
                },
                userInfo: {
                    isgoogle: false,
                    is_pay_pass: false
                },
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                loading: false,
                btnLoading: false
            }
        },
        created() {
            let coin_name = this.$route.query.coin_name
            if(coin_name) {
                this.form.to_user_credit_type = coin_name
            }
            this.getBalance()
            this.getUserInfo()
            this.getWithdrawInfo()
            this.getRecordList(true)
        },
        computed: {
            productList() {
                return this.$store.state.productList
            },
            
            withdrawCoins() {
                return this.$store.state.withdrawCoins
            },
            
            exchangeRate() {
                if(this.form.to_user_credit_type == 'USDT' || this.form.to_user_credit_type == 'USDC') {
                    return 1
                } else {
                    let coin = this.productList.find(item => item.name == this.form.to_user_credit_type)
                    return coin ? parseFloat(coin.price.close) : 1
                }
            },
            
            coinAmount() {
                let num = this.form.num ? parseFloat(this.form.num) : 0
                return (num / this.exchangeRate).toFixed(8)
            },
            
            fee() {
                let fee = this.coinFee[this.form.to_user_credit_type] ? parseFloat(this.coinFee[this.form.to_user_credit_type]) / 100 : 0
                return (parseFloat(this.coinAmount) * fee).toFixed(8)
            },
            
            actualAmount() {
                let is_negative = parseFloat(this.coinAmount) > parseFloat(this.fee)
                return is_negative ? (parseFloat(this.coinAmount) - parseFloat(this.fee)).toFixed(8) : (0).toFixed(8)
            },
            
            curStatus() {
                return (status) => {
                    let obj = {
                        color: '',
                        name: ''
                    }
                    switch(status) {
                        case 0:
                            obj = {
                                color: '#08B5E0',
                                name: this.$t('Assets.Status1')
                            }
                            break
                        case 1:
                            obj = {
                                color: '#01BD8B',
                                name: this.$t('Assets.Status2')
                            }
                            break
                        case -1:
                            obj = {
                                color: '#FB474E',
                                name: this.$t('Assets.Status3')
                            }
                            break
                        case 3:
                            obj = {
                                color: '#FB474E',
                                name: this.$t('Assets.Status4')
                            }
                            break
                        case -2:
                            obj = {
                                color: '#909399',
                                name: this.$t('Assets.Status5')
                            }
                            break
                    }
                    
                    return obj
                }
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
            
            getUserInfo() {
                this.$api.user_info().then(res => {
                    this.userInfo = res.data
                })
            },
            
            getWithdrawInfo() {
                this.$api.wfee({},300000).then(res => {
                    this.coinFee = res.data.fees
                    this.min = res.data.min_withdraw ? parseFloat(res.data.min_withdraw) : 0
                })
            },
            
            withdraw() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        let form = JSON.parse(JSON.stringify(this.form))
                        if(!this.userInfo.isgoogle) {
                            delete form.google_code
                        }
                        this.$api.withdraw(form).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            this.form.pay_pass = ''
                            this.form.google_code = ''
                            this.getBalance()
                            this.getRecordList(true)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            },
            
            cancel(id) {
                this.btnLoading = true
                this.$api.withdraw_cancel({
                    id
                }).then(res => {
                    this.btnLoading = false
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.getBalance()
                    this.getRecordList(true)
                }).catch(err => {
                    this.btnLoading = false
                })
            },
            
            getRecordList(bool=false) {
                this.$api.withdraw_list({
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 40px;
        background-color: #f6f6f6;
        
        .main {
            width: $main-width;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 28px 32px 50px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 28px;
                    font-size: 17px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                .info-wrap {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 32px;
                    
                    /deep/ .el-form-item {
                        width: 100%;
                        margin-bottom: 0;
                    }
                    
                    .info-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 20px;
                        cursor: default;
                        
                        &:first-child {
                            margin-bottom: 0;
                        }
                        
                        .label {
                            min-width: 100px;
                            max-width: 100px;
                            margin-right: 20px;
                            font-size: 14px;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .select-box {
                            width: 100%;
                            
                            /deep/ .el-input__prefix {
                                position: absolute;
                                top: 50%;
                                right: 40px;
                                transform: translateY(-50%);
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                font-size: 14px;
                                color: #6D6F7E;
                                white-space: nowrap;
                            }
                            
                            /deep/ .el-input__inner {
                                height: 48px;
                                line-height: 48px;
                                padding: 0 35px 0 20px;
                                border: 0;
                                font-size: 14px;
                                color: #000000;
                                background-color: #F6F7FA;
                            }
                        }
                        
                        .value {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 48px;
                            box-sizing: border-box;
                            padding: 0 20px;
                            border-radius: 4px;
                            font-size: 14px;
                            color: #000000;
                            word-break: break-all;
                            background-color: #F6F7FA;
                        }
                        
                        .form-input {
                            width: 100%;
                            
                            /deep/ .el-input__inner {
                                height: 48px;
                                line-height: 48px;
                                padding: 0 20px;
                                border: 0;
                                border-radius: 4px;
                                font-size: 14px;
                                color: #000000;
                                background-color: #F6F7FA;
                            }
                        }
                        
                        .form-item {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 48px;
                            line-height: 48px;
                            box-sizing: border-box;
                            padding: 0 20px 0 0;
                            border-radius: 4px;
                            background-color: #F6F7FA;
                            
                            .suffix {
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                                
                                .symbol {
                                    font-size: 14px;
                                    color: #000000;
                                }
                                
                                .all {
                                    margin-left: 12px;
                                    font-size: 14px;
                                    color: #5466EF;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    
                    .set-btn {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #5466EF;
                        cursor: pointer;
                    }
                    
                    .balance {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                        margin: 4px 0 20px;
                        font-size: 14px;
                        color: #A3A6BB;
                        word-break: break-all;
                    }
                    
                    .confirm-btn {
                        width: 100%;
                        height: 55px;
                        box-sizing: border-box;
                        margin-top: 30px;
                        border: 0;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 28px 32px 50px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 28px;
                    font-size: 17px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                .status-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    
                    .status {
                        width: 100%;
                    }
                    
                    .cancel-btn {
                        height: 28px;
                        box-sizing: border-box;
                        padding: 0 10px;
                        margin-top: 4px;
                        border: 0;
                        border-radius: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #e6a23c;
                        cursor: pointer;
                    }
                }
                
                /deep/ .el-table th.is-leaf {
                    padding: 12px 0;
                    background-color: #ECEEF8;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        font-weight: normal;
                        color: #6D6F7E;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 16px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table__empty-block {
                    min-height: 100px;
                
                    .el-table__empty-text {
                        line-height: 100px;
                        font-size: 15px;
                    }
                }
                
                /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                    background-color: #F3F5FF;
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 32px 0 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #5466EF;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>