<template>
    <div class="pc-container">
        <BACK />
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <el-table :data="recordList" style="width: 100%;">
            <el-table-column prop="title" :label="$t('Finance.ProductName')">
            </el-table-column>
            <el-table-column :label="$t('Finance.ReferenceAnnualized') + `（${$t('Finance.DailyRate')}）`">
                <template slot-scope="scope">
                    <span>{{(365 * parseFloat(scope.row.rate)).toFixed(2)}}%</span>
                </template>
            </el-table-column>
            <el-table-column prop="day" :label="$t('Finance.LockPeriod') + `（${$t('Finance.day')}）`">
            </el-table-column>
            <el-table-column :label="$t('Finance.PurchaseAmount')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.money)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Finance.CumulativeIncome')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.all_get)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('Finance.StartingTime')">
            </el-table-column>
            <el-table-column prop="end_at" :label="$t('Finance.EndTime')">
            </el-table-column>
        </el-table>
        <!-- <div class="pagination-box">
            <el-pagination @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="11"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="total, prev, pager, next, jumper"
                :total="total" background>
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
    export default {
        components: {
            BACK: () => import('@/components/back.vue')
        },
        data() {
            return {
                tabList: [this.$t('Finance.Pending'), this.$t('Finance.Completed')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            this.getRecordList()
        },
        methods: {
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.getRecordList()
                }
            },
            
            getRecordList() {
                this.$api.financeHistory({
                    type: this.curIndex
                }).then(res => {
                    this.recordList = res.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 50px;
        background-color: #FFFFFF;
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 50px;
            border-bottom: 1px solid #E9E9E9;
            
            .tab-item {
                min-width: 120px;
                height: 45px;
                line-height: 45px;
                box-sizing: border-box;
                padding: 0 16px;
                border-radius: 2px;
                font-size: 14px;
                color: #000000;
                text-align: center;
                white-space: nowrap;
                background-color: #F5F6F9;
                cursor: pointer;
                
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
            
            .active {
                color: #FFFFFF;
                background-color: #5466EF;
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 18px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 18px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 15px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 50px 0 0 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>