<template>
    <div class="not-found">
        <div class="content">404 Not Found</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .not-found {
        width: 100%;
        box-sizing: border-box;
        padding: 150px 30px;
        
        .content {
            width: 100%;
            font-size: 36px;
            font-weight: bold;
            color: #000000;
            text-align: center;
            word-wrap: break-word;
        }
    }
</style>