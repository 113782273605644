
import Datafeeds from './datafeed'

export const widgetOptions = (opiton) => {
	   if(document.body.clientWidth > 800) {
			var showLegend = true
			var left_toolbar = ''
	   } else {
			var showLegend = false
			var left_toolbar = 'left_toolbar'
	   }
       return {
            // debug:true,
            symbol: opiton.symbol,
            datafeed: new Datafeeds.UDFCompatibleDatafeed(opiton.datafeedUrl),
            interval: opiton.interval,
            container_id: opiton.containerId,
            library_path: opiton.libraryPath,
            locale: opiton.lang || 'en',
            // loading_screen: {foregroundColor: '#000',backgroundColor:'#000'},
            custom_css_url: opiton.cssurl,
            overrides: {
                'scalesProperties.lineColor' : "#37425b",
                'scalesProperties.textColor' : "#999999",
                'scalesProperties.backgroundColor' : "#FFFFFF",
                'mainSeriesProperties.areaStyle.color1': '#414b63', // 面积图颜色
                'mainSeriesProperties.areaStyle.color2': '#323a4f',
                'mainSeriesProperties.areaStyle.linecolor': '#B8B6B6',
                'mainSeriesProperties.candleStyle.upColor': "#01BD8B",
                'mainSeriesProperties.candleStyle.downColor': "#FB474E",
				'paneProperties.legendProperties.showLegend': showLegend,
                ...opiton.overrides
            },
            loading_screen: { backgroundColor: "#131722",foregroundColor: '#131722' },
            disabled_features: [
				left_toolbar,
                'context_menus',
                'timeframes_toolbar',
                'adaptive_logo',
                'display_market_status',
                'show_hide_button_in_legend',
                'header_widget',
                'compare_symbol',
                'volume_force_overlay',
                'control_bar',
                'symbol_info',
                'countdown',
                'symbol_search_hot_key',
                'widget_logo'
            ],
            enabled_features: [ 'study_templates','hide_last_na_study_output', 'move_logo_to_main_pane'],
            charts_storage_url: opiton.chartsStorageUrl,
            charts_storage_api_version:  '1.16',
            client_id: '0',
            user_id: 'public_user_id',
            fullscreen: false,
            autosize: true,
            studies_overrides: {
                // 'ma.color':'#ddd',
                ...opiton.studiesOverrides
            },
            timezone:'exchange',
           
       } 
}