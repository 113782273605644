<template>
    <div class="pc-container">
        <div class="content">
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 21.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal
                        Terms of Use</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">[Last
                        revised: 15 August 2019]</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">This
                    agreement is between you (referenced herein as &ldquo;you&rdquo; or with &ldquo;your&rdquo;) and </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.
                    By accessing, using or clicking &ldquo;I agree&rdquo; to any of the services made available by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    one of its affiliates through the website (</span><a href="https://www.neodeal.net/"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">https://</span></u><u><span
                            class="15"
                            style="font-family: 宋体; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">www.neodeal.net</span></span></u><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">),
                    our mobile applications, or any other related services provided by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    its affiliates as further described in Section 4 below (collectively, the &ldquo;Services&rdquo;) you agree that
                    you have read, understood and accepted all of the terms and conditions contained in this Terms of Use
                    agreement.&nbsp;Additionally, when using certain features of the Services, you may be subject to additional
                    terms and conditions applicable to such features.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">PLEASE
                    READ THESE TERMS CAREFULLY AS THEY GOVERN YOUR USE OF THE SERVICES. THESE TERMS CONTAINS IMPORTANT PROVISIONS
                    INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION. THE
                    TERMS OF THE ARBITRATION PROVISION ARE SET FORTH IN SECTION 14 BELOW ENTITLED &ldquo;RESOLVING DISPUTES: FORUM,
                    ARBITRATION, CLASS ACTION WAIVER, GOVERNING LAW.&rdquo; AS WITH ANY ASSET, THE VALUE OF DIGITAL CURRENCIES CAN
                    GO UP OR DOWN AND THERE CAN BE A SUBSTANTIAL RISK THAT YOU WILL LOSE MONEY BUYING, SELLING, HOLDING, OR
                    INVESTING IN DIGITAL CURRENCIES. BY USING THE SERVICES YOU ACKNOWLEDGE AND AGREE THAT (1) YOU ARE AWARE OF THE
                    RISKS ASSOCIATED WITH TRANSACTING IN DIGITAL CURRENCIES (2) THAT YOU ASSUME ALL RISKS WITH RESPECT TO YOUR USE
                    OF THE SERVICES AND TRADING IN DIGITAL CURRENCIES AND (3) </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;IS
                    NOT RESPONSIBLE OR LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">BY
                    ACCESSING, USING OR ATTEMPTING TO USE THE SERVICES IN ANY CAPACITY, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO
                    BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, DO NOT ACCESS OR USE THE SERVICES.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">1.
                        Agreement Conditions</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;reserves
                    the right to modify or change the Terms at any time and at its sole discretion. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    provide notice of these changes by updating the revised Terms and changing the &ldquo;[Last revised: ]&rdquo;
                    date on this page. Any and all modifications or changes to these Terms will be effective immediately upon being
                    announced on the website or released to users. As such, your continued use of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    services acts as acceptance of the amended agreement and rules. If you do not agree to any modification to these
                    Terms, you must stop using the Services. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;encourages
                    you to frequently review the Terms to ensure you understand the terms and conditions that apply to your access
                    to, and use of, the Services.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">2.
                        Eligibility</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">By
                    registering to use a </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Account
                    (as defined in Section 5 below), you represent and warrant that you (a) are at least 18 years old or of legal
                    age to form a binding contract under applicable law, (b) are an individual, legal person or other organization
                    with full legal capacity and authority to enter into these Terms, (c) have not previously been suspended or
                    removed from using our Services and (d) you do not currently have an existing </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Account,
                    (e) you are not a U.S person. If you are entering into these Terms on behalf of a legal entity of which you are
                    an employee or agent, you represent and warrant that you have all necessary rights and authority to bind such
                    legal entity.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">3.
                        Prohibition of Use</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">By
                    accessing and using the Services, you represent and warrant that you are not on any trade or economic sanctions
                    lists, such as the UN Security Council Sanctions list, designated as a &ldquo;Specially Designated
                    National&rdquo; by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) or placed on the U.S.
                    Commerce Department&rsquo;s &ldquo;Denied Persons List&rdquo;. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;maintains
                    the right to select its markets and jurisdictions to operate and may restrict or deny the Services in certain
                    countries at its discretion.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">4.
                        Description of Services</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;provides
                    an online digital asset trading platform (crypto to crypto) for products commonly known as cryptographic tokens,
                    digital tokens or cryptographic currency (collectively, &ldquo;Digital Currency&rdquo;). </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;does
                    not provide fiat trading capabilities on as part of the Services. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;functions
                    as a trading platform provider and not as a buyer or seller in trades made between traders. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;is
                    also not a market maker. Users must register and open an account with </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;as
                    further set forth in Section 5 below and deposit digital assets prior to commencement of trading. Traders may
                    request the withdrawal of their digital assets, subject to the limitations as stated in these Terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;strives
                    to maintain the accuracy of information posted on the Services however it cannot and does not guarantee the
                    accuracy, suitability, reliability, completeness, performance or fitness for any purpose of the content made
                    available through the Services, and will not be liable for any loss or damage that may arise directly or
                    indirectly from your use of such content. Information on the Services can be subjected to change without notice
                    and is provided for the primary purpose of facilitating users to arrive at independent decisions. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;does
                    not provide investment or advisory advice and will have no liability for the use or interpretation of
                    information as stated on the Services or other communication mediums. All users of the Services must understand
                    that there are risks involved in trading in Digital Currencies. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;encourages
                    all users to exercise prudence and trade responsibly within their own means.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">5.
                    </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Account
                        Registration &amp; Requirements</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Registration</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">All
                    users of the Services (each, a &ldquo;User&rdquo;) must register at (</span><a
                    href="https://www.NeoDeal.top/#/register"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">https://</span></u><u><span
                            class="15"
                            style="font-family: 宋体; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">www.NeoDeal</span></span></u><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top/#/register</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">)
                    for a </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;account
                    (an &ldquo;Account&rdquo;) before using the Services. To register for an Account, you must provide your real
                    name, email address and password, as well as accept the Terms of Use, Privacy Policy and Consent Form.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may,
                    in its sole discretion, refuse to open an account for you. You agree to provide complete and accurate
                    information when opening an Account and agree to promptly update any information you provide to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;so
                    that such information is complete and accurate at all times. Each registration is for a single user only and
                    each User (including with respect to any User that is a business or legal entity) may only maintain one active
                    Account with </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        User Identity Verification</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">With
                    registration of an account on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    you agree to share personal information requested for the purposes of identity verification. This information is
                    used specifically for the detection of money laundering, terrorist financing, fraud and other financial crimes
                    on the </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;platform.
                    We will collect, use and share this information in accordance with our posted Privacy Policy. In addition to
                    providing this information, to facilitate compliance with global industry standards for data retention, you
                    agree to permit us to keep a record of such information for the lifetime of your account plus 5 years beyond
                    account closing. You also authorise us to make inquiries, either directly or through third parties, that are
                    deemed necessary to verify your identity or to protect you and/or us against financial crimes such as
                    fraud.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">The
                    identity verification information we request may include, but is not limited to, your: Name, Email Address,
                    Contact Information, Telephone Number, Username, Government Issued ID, Date of Birth and other information
                    collected at the time of account registration. In providing this required information, you confirm that it is
                    accurate and authentic. Post-registration, you must guarantee that the information is truthful, complete and
                    updated in a timely manner with any changes. If there is any reasonable doubt that any information provided by
                    you is wrong, untruthful, outdated or incomplete, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to send you a notice to demand corrections, remove relevant information directly and, as the case
                    may be, terminate all or part of the Services to you. You shall be solely and fully responsible for any loss or
                    expenses incurred during the use of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Service
                    if you cannot be reached through the contact information provided. You hereby acknowledge and agree that you
                    have the obligation to keep all information provided up to date if there are any changes.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">BY
                    SIGNING UP FOR ACCOUNT YOU HEREBY AUTHORIZE </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;TO
                    MAKE INQUIRIES, WHETHER DIRECTLY OR THROUGH THIRD PARTIES, THAT </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;CONSIDERS
                    NECESSARY TO VERIFY YOUR IDENTITY OR PROTECT YOU AND/OR </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;AGAINST
                    FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE ACTION </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;REASONABLY
                    DEEMS NECESSARY BASED ON THE RESULTS OF SUCH INQUIRIES. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL
                    INFORMATION MAY BE DISCLOSED TO CREDIT REFERENCE AND FRAUD PREVENTION OR FINANCIAL CRIME AGENCIES AND THAT THESE
                    AG ENCIES MAY RESPOND TO OUR INQUIRIES IN FULL.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                        Account Usage Requirements</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Accounts
                    can only be used by the person whose name they are registered under. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;reserves
                    the right to suspend, freeze or cancel accounts that are used by persons other than the persons whose names they
                    are registered under. You shall immediately notify </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;if
                    you suspect or become aware of unauthorized use of your user name and password. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not be liable for any loss or damage arising from any use of your Account by you or by any third party (whether
                    or not authorized by you).</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">d.
                        Account Security</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;strives
                    to maintain the safety of those user funds entrusted to us and has implemented industry standard protections for
                    the Services. However, there are risks that are created by individual User actions. You agree to consider your
                    access credentials such as user name and password as confidential information and not to disclose such
                    information to any third party. You also agree that you alone are responsible for taking necessary safety
                    precautions to protect your own account and personal information.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    shall be solely responsible for the safekeeping of your </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;account
                    and password on your own, and you shall be responsible for all activities under Account and </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not be responsible for any loss or consequences of authorized or unauthorized use of your Account credentials
                    including but not limited to information disclosure, information posting, consent to or submission of various
                    rules and agreements by clicking on the website, online renewal of agreement, etc.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">By
                    creating an Account, you hereby agree that:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">(i)
                    you will notify </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;immediately
                    if you are aware of any unauthorized use of your </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;account
                    and password by any person or any other violations to the security rules;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">(ii)
                    you will strictly observe the security, authentication, dealing, charging, withdrawal mechanism or procedures of
                    the website/service; and</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">(iii)
                    you will log out from the website by taking proper steps at the end of every visit.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">6.
                        Guidelines for Usage of the Services</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        License</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Subject
                    to your continued compliance with the express terms and conditions of these Terms, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;provides
                    to you a revocable, limited, royalty-free, non-exclusive, non-transferable, and non-sublicensable license to
                    access and use the Services on your computer or other internet compatible device for your personal, internal use
                    only. You are not permitted to use the Services for any resale or commercial use including to place trades on
                    behalf of another person or entity. All such use is expressly prohibited and shall constitute a material
                    violation of these Terms. The content layout, formatting, and features of and access privileges for the Services
                    shall be as specified by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;in
                    its sole discretion. All rights not expressly granted under these Terms are hereby reserved. Accordingly, you
                    are hereby prohibited from using the Services in any manner that is not expressly and unambiguously authorized
                    by these Terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">These
                    Terms provide only a limited license to access and use the Services. Accordingly, you hereby agree that
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;transfers
                    no ownership or intellectual property interest or title in and to the Services or any </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;intellectual
                    property to you or anyone else in connection with your use of the Services. All text, graphics, user interfaces,
                    visual interfaces, photographs, sounds, artwork, computer code (including html code), programs, software,
                    products, information, and documentation as well as the design, structure, selection, coordination, expression,
                    &ldquo;look and feel,&rdquo; and arrangement of any content contained on or available through the Services are
                    exclusively owned, controlled, and/or licensed by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    its members, parent(s), licensors, or affiliates.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    own any feedback, suggestions, ideas, or other information or materials regarding </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    the Services that you provide, whether by email, through the Services or otherwise (&ldquo;Feedback&rdquo;). You
                    hereby assign to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;all
                    right, title and interest to Feedback together with all associated intellectual property rights. You will not be
                    entitled to, and hereby waive any claim for, acknowledgment or compensation based on any Feedback or any
                    modifications made based on any Feedback.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Restrictions</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">When
                    you use the Services you agree and covenant to observe the following:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">All
                    the activities that you carry out during the use of the Services will be in compliance with the requirements of
                    applicable laws, regulations, as well as the various guidelines of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">:</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Your
                    use of the Services will not be in violation of public interests, public ethics or other&rsquo;s legitimate
                    interests including taking any action that would interfere with, disrupt, negatively affect, or inhibit other
                    Users from using the Services:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree not to use the services for engaging in market manipulation (such as pump and dump schemes, wash trading,
                    self-trading, front running, quote stuffing, and spoofing or layering regardless of whether prohibited by
                    law);</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">The
                    following commercial uses of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;data
                    is prohibited unless written consent from </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;is
                    granted:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">1)
                    Exchange services that use quotes or order book information from </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">2)
                    Data feed or data stream services that make use of any market data from </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">3)
                    Any other websites/apps/services that charge for, or otherwise commercially monetize (including through
                    advertising or referral fees), market data obtained from </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    may not modify, reproduce, duplicate, copy, download, store, further transmit, disseminate, transfer,
                    disassemble, broadcast, publish, remove or alter any proprietary notices or labels, license, sublicense, sell,
                    mirror, frame, rent, lease, private label, grant a security interest in, create derivative works of, or
                    otherwise exploit the Properties, or any portion of the Properties without </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    prior written consent.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    may not (i) use any "deep link," "page scrape," "robot," "spider," or other automatic device, program, script,
                    algorithm, or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any
                    portion of the Properties or in any way reproduce or circumvent the navigational structure or presentation of
                    the Services to obtain or attempt to obtain any materials, documents, or information through any means not
                    purposely made available through the Services, (ii) attempt to gain unauthorized access to any portion or
                    feature of the Properties or any other systems or networks connected to the Services or to any </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;server
                    or to any of the services offered on or through the Services, by hacking, password "mining," or any other
                    illegitimate or prohibited means, (iii) probe, scan, or test the vulnerability of the Services or any network
                    connected to the Properties, nor breach the security or authentication measures on the Services or any network
                    connected to the Services, (iv) reverse look-up, trace, or seek to trace any information on any other user of or
                    visitor to the Services, (v) take any action that imposes an unreasonable or disproportionately large load on
                    the infrastructure of the Services or </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    systems or networks or any systems or networks connected to the Services, (v) use any device, software, or
                    routine to interfere with the proper working of the Services or any transaction conducted on the Services, or
                    with any other person's use of the Services, (vi) forge headers, impersonate a person, or otherwise manipulate
                    identifiers in order to disguise your identity or the origin of any message or transmittal you send to the
                    Services, or (vii) use the Services in an unlawful manner.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">By
                    accessing the Service, you agree that </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to investigate any violation of these Terms, unilaterally determine whether you have violated
                    these Terms, and take actions to apply relevant rules without receiving your consent or giving prior notice to
                    you. Examples of such actions include, but are not limited to:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">block
                    and close order requests</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">freezing
                    your account</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">reporting
                    the incident to authorities</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">publishing
                    the alleged violations and actions that have been taken</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">deleting
                    any information you published that is in violation</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">7.
                        Orders and Service Fees</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Orders</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Upon
                    placing an instruction to effect a trade using the Services (an &ldquo;Order&rdquo;), your Account will be
                    updated to reflect the open Order and your Order will be included in </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    order book for matching with Orders from other Users. If all or a portion of your Order is matched with another
                    User, the Services will execute an exchange (a &ldquo;Trade&rdquo;). Upon execution of a Trade, your Account
                    will be updated to reflect that the Order has either been closed due to having been fully executed, or updated
                    to reflect any partial fulfillment of the Order. Orders will remain open until fully executed or cancelled in
                    accordance with subsection (b) below. For purposes of effectuating a Trade, you authorize </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;to
                    take temporary control of the Digital Currency that you are disposing of in the Trade.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Cancellations</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    may only cancel an order initiated via the Services if such cancellation occurs before your Order has been
                    matched with an Order from another user. Once your Order has been matched with an Order from another user, you
                    may not change, withdraw, or cancel your authorization for </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;to
                    complete such Order. If any order has been partially matched, you may cancel the unmatched portion of the Order
                    unless and until the unmatched portion has been matched. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;reserves
                    the right to refuse any cancellation request associated with an Order after you have submitted such Order. If
                    you have an insufficient amount of Digital Currency in your Account to fulfill an Order, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may
                    cancel the entire Order or may fulfill a partial Order that can be covered by the Digital Currency in your
                    Account (in each case after deducting any fees payable to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;in
                    connection with the Trade as described in subsection (c) below).</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                        Fees</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree to pay </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;the
                    fees set which may be updated from time to time in </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    sole discretion. Any such updated fees will apply prospectively to any trades or other transactions that take
                    place following the effective date of such updated fees. You authorize </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;to
                    remove any amounts from your Account for any applicable fees owed by you under these Terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">8.
                        Margin Trading and Futures</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Risks：</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Margin
                    trading and Futures are highly risky. As a margin or futures trader, you acknowledge and agree that you shall
                    access and use the margin or futures trading and borrowing service at your own risks:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Trading
                    markets are extremely volatile and shift quickly in terms of liquidity, market depth, and trading dynamics. The
                    use of leverage can work against you as well as for you and can lead to large losses as well as gains.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    are not eligible for receiving forked coins split from any blockchain asset in your margin or futures account,
                    even if you have not engaged in any margin, futures trading or borrowing at all.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">The
                    loan carries risks and the value of your blockchain assets may drop. If the value of your assets drops below a
                    certain level, you are responsible for responding to this market circumstance.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Under
                    certain market conditions, you may find it difficult or impossible to liquidate a position. This can occur, for
                    example, there is insufficient liquidity in the market or due to technical issues on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Placing
                    contingent orders will not necessarily limit your losses to the intended amounts, since market conditions may
                    make it impossible to execute such orders.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">There
                    is no guaranteed measure against losses on margin or futures trading. As a borrower, you may sustain a total
                    loss beyond what you have deposited to your margin or futures account.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        To enter into margin or futures trading:</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    represent and warrant that you are not either from the US, nor on any trade or economic sanctions lists, such as
                    designated as a &ldquo;Specially Designated National&rdquo; by OFAC (Office of Foreign Assets Control of the
                    U.S. Treasury Department).</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    should fully understand the risks associated with the margin or futures trading and lending and solely
                    responsible and liable for any and all trading and non-trading activity on your </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;account.
                    Do not enter into a transaction or invest in funds that are above your financial abilities;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    are solely responsible and liable for knowing the true status of any position, even if presented incorrectly by
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;at
                    any time;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree to maintain in your </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;margin
                    or futures account a sufficient amount of blockchain assets required by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;for
                    users to engage in margin or futures trading and return your loan in full timely. Failure to maintain a
                    sufficient amount of assets or fail to return outstanding loan duly can result in the forced-liquidation of
                    assets in your margin or futures account;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;cannot
                    guarantee to stop losses even with the ability to force-liquidate any of your positions. If, after your
                    positions are liquidated, your assets are insufficient to cover the outstanding loan, you are still responsible
                    for any additional assets shortfall;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may,
                    in its sole discretion, perform measures to mitigate potential losses to you on your behalf, including, but not
                    limited to, transferring balances from your margin or futures to your exchange account and/or from your exchange
                    to your margin or futures account;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">During
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;system
                    maintenance, you agree that you are solely responsible and liable for managing your margin or futures account
                    under risk, including but not limited to, keep or close your position and repay your loan.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree that you conduct all trading, margin trading, futures trading and/or borrowing on your own account and
                    claim full responsibility for your activities. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;does
                    not take any responsibility for any loss or damage incurred as a result of your use of any services or your
                    failure to understand the risks involved associated with assets use generally or your use of our
                    services.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    are aware that on liquidation, all open orders are immediately cancelled. you may lose all your positions and
                    all the funds in your futures account. A fee of up to 0.5% of the NOMINAL position value will be charged. If
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;cannot
                    close all the positions on liquidation,</span><a href="https://www.NeoDeal.top/#/ServiceTerms"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">&ensp;the insurance fund&ensp;</span></span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">and
                    / or</span><a href="https://www.NeoDeal.top/#/ServiceTerms"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">&ensp;counterparty-liquidation&ensp;</span></span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">will
                    take effect. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;takes
                    every possible step to avoid counterparty liquidation to minimize the potential impact of any counterparty
                    liquidation when it does occur. Unfortunately, due to the volatility in the Crypto markets, and the high
                    leverage offered to clients, it is not possible to fully avoid this possibility. In order to provide the best
                    possible client experience, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;are
                    striving to keep counterparty liquidations to an absolute minimum.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">9.
                    </span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span></strong><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending</span></strong>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    launched </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service for </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    users to gain proceeds through idle cryptocurrency assets.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    assets will be used in cryptocurrency leveraged borrowing business on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top;</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                    When you use </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service, you will unconditionally authorize </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    to distribute the leveraged interest according to the rules of the platform.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">d.
                    You shall abide by the relevant laws of the State to ensure that the sources of assets are legitimate and
                    compliant when using </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">e.
                    When you use </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service, you should fully recognize the risks of investment in cryptocurrency and operate cautiously.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">f.
                    You agree that all investment operations conducted on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    represent your true investment intentions and that unconditionally accept the potential risks and benefits of
                    your investment decisions.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">g.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    reserves the right to suspend or terminate </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service. If necessary, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    can suspend and terminate </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service at any time.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">h.
                    Due to network delay, computer system failures and other force majeure, which may lead to delay, suspension or
                    deviation of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service execution, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    will use commercially reasonable effort to ensure but not promise that </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Lending
                    service execution system run stably and effectively. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">www.NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top
                    does not take any responsibility if the final execution doesn&rsquo;t match your expectations due to the above
                    factors.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">10.
                        Liability</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Disclaimer of Warranties</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">TO
                    THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE SERVICES, THE </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;MATERIALS
                    AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;ARE
                    PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS AND </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;EXPRESSLY
                    DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
                    WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR
                    NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT
                    LIMITING THE FOREGOING, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;DOES
                    NOT REPRESENT OR WARRANT THAT THE SITE, THE SERVICES OR </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;MATERIALS
                    ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;DOES
                    NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS
                    STATEMENTS SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER
                    STATEMENT OR UNDERSTANDING, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THE SERVICES.
                    WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;WILL
                    NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF
                    DIGITAL CURRENCY PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C) INTERRUPTION IN ANY
                    SUCH DATA AND (D) ANY DAMAGES INCURRED BY ANOTHER USER&rsquo;S ACTIONS, OMISSIONS OR VIOLATION OF THIS
                    AGREEMENT.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">THE
                    DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO THE EXTENT IT IS PROHIBITED BY
                    APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU RESIDE.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Disclaimer of Damages and Limitation of Liability</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">TO
                    THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                    REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE,
                    CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                    DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESS OR FINANCIAL BENEFIT) ARISING OUT OF OR IN CONNECTION WITH
                    THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM
                    PROVIDED BY OR ON BEHALF OF </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;AND
                    ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;HAS
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH
                    DAMAGES WERE A RESULT OF </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;S
                    GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO
                    YOU.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NOTWITHSTANDING
                    THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                    REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF OR IN CONNECTION THE SERVICES, ANY PERFORMANCE OR
                    NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;OR
                    ITS AFFILIATES WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES
                    PAID BY YOU TO </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;UNDER
                    THIS AGREEMENT IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
                    LIABILITY.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                        Indemnification</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree to indemnify and hold harmless </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    its affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and
                    against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including
                    attorneys&rsquo; fees, fines or penalties imposed by any regulatory authority) arising out of or related to (i)
                    your use of, or conduct in connection with, the Services, (ii) your breach or our enforcement of these Terms, or
                    (iii) your violation of any applicable law, regulation, or rights of any third party during your use of the
                    Service. If you are obligated to indemnify </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    its affiliates, contractors, licensors, and their respective directors, officers, employees or agents pursuant
                    to this clause, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    have the right, in its sole discretion, to control any action or proceeding and to determine whether
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;wishes
                    to settle, and if so, on what terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">11.
                        Announcements</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Please
                    be aware that all official announcements, news, promotions, competitions and airdrops will be listed on
                    &nbsp;</span><a href="https://www.NeoDeal.top/#/announcement"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Announcements</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    where we urge all users to refer to regularly. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not be held liable or responsible in any manner of compensation should users incur personal losses arising from
                    ignorance or negligence of the announcements.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">12.
                        Termination of Agreement</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    agree that </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to immediately suspend your account (and any accounts beneficially owned by related entities or
                    affiliates), freeze or lock the funds in all such accounts, and suspend your access to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;for
                    any reason including if it suspects any such accounts to be in violation of these Terms, our Privacy Policy, or
                    any applicable laws and regulations. You agree that </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    not be liable to you for any permanent or temporary modification, suspension or termination of your Account or
                    access to all or any portion of the Services. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to keep and use the transaction data or other information related to such accounts. The above
                    account controls may also be applied in the following cases:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">The
                    account is subject to a governmental proceeding, criminal investigation or other pending litigation;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    detect unusual activity in the account;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    detect unauthorized access to the account;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We
                    are required to do so by a court order or command by a regulatory/government authority</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">In
                    case of any of the following events, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to directly terminate this agreement by cancelling your Account, and shall have the right to
                    permanently freeze (cancel) the authorizations of your account on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;and
                    withdraw the corresponding </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Account
                    thereof:</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">after
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;terminates
                    services to you;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">you
                    allegedly register or register in any other person&rsquo;s name as </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;user
                    again, directly or indirectly;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">the
                    information that you have provided is untruthful, inaccurate, outdated or incomplete; when these Terms are
                    amended, you expressly state and notify </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;of
                    your unwillingness to accept the amended Terms;</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">you
                    request that the Services be terminated; and</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 9.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&middot;&nbsp;</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">any
                    other circumstances where </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;deems
                    it should terminate the services.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Should
                    your Account be terminated, the Account and transactional information required for meeting data retention
                    standards will be securely stored for 5 years. In addition, if a transaction is unfinished during the Account
                    termination process, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;shall
                    have the right to notify your counterparty of the situation at that time. You acknowledge that a user initiated
                    account exit (right to erasure under GDPR or other equivalent regulations) will also be subjected to the
                    termination protocol stated above.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">If
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;receives
                    notice that any funds held in your Account are alleged to have been stolen or otherwise are not lawfully
                    possessed by you, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may,
                    but has no obligation to, place an administrative hold on the affected funds and your Account. If </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;does
                    place an administrative hold on some or all of your funds or Account, </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may
                    continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;has
                    been provided to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;in
                    a form acceptable to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not involve itself in any such dispute or the resolution of the dispute. You agree that </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    have no liability or responsibility for any such hold, or for your inability to withdraw funds or execute trades
                    during the period of any such hold.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Remaining funds after Account termination</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Except
                    as set forth in subsection (b) below, once the Account is closed/withdrawn, all remaining balance (which
                    includes charges and liabilities owed to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">)
                    on the account will be payable immediately to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.
                    Upon payment of all outstanding charges to </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;(if
                    any), the User will have 5 business days to withdraw all funds from the Account.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Remaining funds after Account termination due to fraud, violation of law, or violation of these
                        terms)</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;maintains
                    full custody of the funds and User data/information which may be turned over to governmental authorities in the
                    event of Account suspension/closure arising from fraud investigations, violation of law investigations or
                    violation of these Terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">13.
                        No Financial Advice</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;is
                    not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in
                    connection with any trades or other decisions or activities effected by you using the Services. No communication
                    or information provided to you by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;is
                    intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any
                    other sort of advice. All trades are executed automatically, based on the parameters of your order instructions
                    and in accordance with posted trade execution procedures, and you are solely responsible for determining whether
                    any investment, investment strategy or related transaction is appropriate for you based on your personal
                    investment objectives, financial circumstances and risk tolerance. You should consult your legal or tax
                    professional regarding your specific situation. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;does
                    not recommend that any Digital Currency should be bought, earned, sold, or held by you. Before making the
                    decision to buy, sell or hold any Digital Currency, you should conduct your own due diligence and consult your
                    financial advisors before making any investment decision. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not be held responsible for the decisions you make to buy, sell, or hold Digital Currency based on the
                    information provided by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">14.
                        Compliance with Local Laws</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">It
                    is the responsibility of the User to abide by local laws in relation to the legal usage of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;in
                    their local jurisdiction. Users must also factor, to the extent of their local law all aspects of taxation, the
                    withholding, collection, reporting and remittance to their appropriate tax authorities. All Users of
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;and
                    any of its services acknowledge and declare that the source of their funds come from a legitimate manner and are
                    not derived from illegal activities. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;maintains
                    a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze,
                    terminate the account and funds of Users which are flagged out or investigated by legal mandate.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">15.
                        Privacy Policy</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Access
                    to the Services will require the submission of certain personally identifiable information. Please review
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    Privacy Policy found at</span><a href="https://www.NeoDeal.top/#/PrivacyStatement"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">&ensp;http://</span></span></u><u><span class="15"
                            style="font-family: 宋体; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">www.NeoDeal</span></span></u><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top/#/PrivacyStatement</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;for
                    a summary of </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    practices related to the collection and use of personally identifiable information.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">16.
                        RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER, GOVERNING LAW</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">PLEASE
                    READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING AS
                    A CLASS ACTION FOR RESIDENTS OF THE U.S.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Notification of Dispute.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Please
                    contact </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;first!
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;wants
                    to address your concerns without resorting to formal legal proceedings. Before filing a claim, you agree to try
                    to resolve the dispute informally by contacting </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;first
                    through&nbsp;</span><a href="https://www.support.NeoDeal.top/hc/en-us/requests/new"><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">https://support.</span></u><u><span
                            class="15"
                            style="font-family: 宋体; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span
                                style="font-family: Helvetica;">www.NeoDeal</span></span></u><u><span class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.top/hc/en-us/requests/new</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Agreement to Arbitrate.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You
                    and </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;agree
                    to resolve any claims relating to this Agreement (including any question regarding its existence, validity,
                    termination, or any services or products provided and any representations made by us) through final and binding
                    arbitration, except as set forth under Exceptions to Agreement to Arbitrate below. You agree to first give us an
                    opportunity to resolve any claims by contacting us as set forth in subsection (a) above. If we are not able to
                    resolve your claims within 60 days of receiving the notice, you may seek relief through arbitration or in the
                    Small Claims Tribunals of Singapore (&ldquo;SCT&rdquo;), as set forth below.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                        Arbitration Procedure.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    Either you or </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may
                    submit a dispute (after having made good faith efforts to resolve such dispute in accordance with subsections
                    (a) and (b) above) for final, binding resolution by arbitration under the arbitration rules of the Singapore
                    International Arbitration Centre (&ldquo;SIAC&rdquo;), which are deemed to be incorporated by reference. The
                    arbitration tribunal shall consist of a sole arbitrator to be appointed by the President of SIAC. The language
                    of the arbitration hearings shall be English and the seat, or legal place, of arbitration shall be
                    Singapore.&nbsp;</span><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Judgment
                        on any arbitral award may be entered in any court having jurisdiction over the party (or the assets of the
                        party) due and owing such award.</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">d.
                        Exceptions.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    Either party may instead choose to assert the claims in the SCT if the claims fall within the jurisdiction of
                    the SCT, and either party may seek injunctive relief or other urgent equitable relief in a court of competent
                    jurisdiction. However, for the avoidance of doubt, where the claims fall outside of the jurisdiction of the SCT,
                    the claims will be referred to and finally resolved by SIAC arbitration.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">e.
                        Notice.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">To
                    begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claims to
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;Europe
                    Services Limited, Melita Court, Level 3, Triq Giuseppe Cali, Ta&rsquo;Xbiex XBX 1420, Malta. If we request
                    arbitration against you, we will give you notice at the email address or street address you have provided. SIAC
                    Rules and filing instructions are available at &nbsp;</span><a href="http://www.siac.org.sg/our-rules"><u><span
                            class="15"
                            style="font-family: Helvetica; color: #0563c1; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">http://www.siac.org.sg/our-rules</span></u></a><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;or
                    by calling +65 6713 9777.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">f.
                        Governing Law.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    This Agreement is governed by the law of Malta except for its conflicts of laws principles, unless otherwise
                    required by a mandatory law of any other jurisdiction.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">17.
                        Miscellaneous</span></strong></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">a.
                        Independent Parties.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;is
                    an independent contractor and not an agent of you in the performance of these Terms. These Terms not to be
                    interpreted as evidence of an association, joint venture, partnership, or franchise between the parties.</span>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">b.
                        Entire Agreement.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    These Terms constitute the entire agreement between the parties regarding use of the Services and will supersede
                    all prior agreements between the parties whether, written or oral. No usage of trade or other regular practice
                    or method of dealing between the parties will be used to modify, interpret, supplement, or alter the terms of
                    these Terms.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">c.
                        Force Majeure.&nbsp;</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;
                </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;will
                    not be liable for any delay or failure to perform as required by these Terms because of any cause or condition
                    beyond </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&rsquo;s
                    reasonable control.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">d.
                        Severability.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    If any portion of these Terms are held invalid or unenforceable, such invalidity or enforceability will not
                    affect the other provisions of these Terms, which will remain in full force and effect, and the invalid or
                    unenforceable portion will be given effect to the greatest extent possible.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">e.
                        Assignment.&nbsp;</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;
                    You may not assign or transfer any right to use the Services or any of your rights or obligations under these
                    Terms without prior written consent from us, including by operation of law or in connection with any change of
                    control. </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;may
                    assign or transfer any or all of its rights or obligations under these Terms, in whole or in part, without
                    notice or obtaining your consent or approval.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">f.
                        Waiver.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;&nbsp;
                    The failure of a party to require performance of any provision will not affect that party&rsquo;s right to
                    require performance at any time thereafter, nor will a waiver of any breach or default of these Terms or any
                    provision of these Terms constitute a waiver of any subsequent breach or default or a waiver of the provision
                    itself.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">g.
                        Third-Party Website Disclaimer.&nbsp;</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;
                    Any links to third-party websites from the Services does not imply endorsement by </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;of
                    any products, services or information presented therein, nor does </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;guarantee
                    the accuracy of the information contained on them. In addition, since </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;has
                    no control over the terms of use or privacy practices of third-party websites, you should read and understand
                    those policies carefully.</span></p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 0pt;"><strong><span
                        style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">h.
                        Contact Information.</span></strong><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For
                    more information on </span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">NeoDeal</span><span
                    style="font-family: Helvetica; color: #393939; letter-spacing: 0pt; font-size: 10.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">,
                    you can refer to the company and license information found on the website. If you have questions regarding this
                    agreement.</span></p>
            <p class="MsoNormal"><span
                    style="mso-spacerun: 'yes'; font-family: Calibri; mso-fareast-font-family: 宋体; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 40px $box-padding;
        background-color: #f6f6f6;
        
        .content {
            width: 100%;
            word-wrap: break-word;
        }
    }
</style>