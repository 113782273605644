<template>
    <div class="header">
        <div class="left">
            <img class="logo" src="@/assets/images/pc/index/logo.png" @click="to('/')" />
            <div class="nav">
                <div v-for="(item,index) in navList" 
                :key="index"
                :class="['nav-item', curIndex == index ? 'active' : '']" 
                @click="to(item.url)">
                    {{item.name}}
                </div>
            </div>
        </div>
        <div class="right">
            <div v-if="token" class="operate">
                <div :class="['item', curUrl == '/assets' ? 'active' : '']" @click="to('/assets')">{{$t('Module.Assets')}}</div>
                <el-popover
                placement="bottom"
                width="80"
                trigger="hover">
                    <div class="popover-title-box">
                        <div class="popover-title" @click="logout">{{$t('Account.Logout')}}</div>
                    </div>
                    <div slot="reference" :class="['item', curUrl == '/account' ? 'active' : '']" @click="to('/account')">{{$t('Module.Account')}}</div>
                </el-popover>
            </div>
            <div v-else class="operate">
                <div :class="['item', curUrl == '/login' ? 'active' : '']" @click="to('/login')">{{$t('Login.Login')}}</div>
                <div :class="['item', curUrl == '/register' ? 'active' : '']" @click="to('/register')">{{$t('Login.Register')}}</div>
            </div>
            <div class="divide-line"></div>
            <el-dropdown @command="changeLang">
                <span class="el-dropdown-link">
                    {{curLang}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item,index) in langList" :key="index" :command="item.key">
                        {{item.value}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import lang_list from '@/utils/langList'
    export default {
        data() {
            return {
                navList: [{
                        name: this.$t('Module.Home'),
                        url: '/'
                    },
                    {
                        name: this.$t('Module.PerpetualContract'),
                        url: '/PerpetualContract'
                    },
                    {
                        name: this.$t('Module.CurrencyTrading'),
                        url: '/CurrencyTrading'
                    },
                    {
                        name: this.$t('Module.SecondContract'),
                        url: '/SecondContract'
                    },
                    // {
                    //     name: '购买加密货币',
                    //     url: '/crypto'
                    // },
                    {
                        name: this.$t('Module.Finance'),
                        url: '/finance'
                    },
                    {
                        name: this.$t('Module.Announcement'),
                        url: '/announcement'
                    }
                ],
                curIndex: -1,
                langList: lang_list,
                curLang: ''
            }
        },
        created() {
            // console.log(this.$route.path)
            this.changeCurIndex(this.$route.path)
            this.getCurLang()
        },
        computed: {
            curUrl() {
                return this.$route.path
            },
            
            token() {
                return this.$store.state.token
            }
        },
        watch: {
            curUrl(val) {
                // console.log(val)
                this.changeCurIndex(val)
            }
        },
        methods: {
            changeCurIndex(path) {
                this.curIndex = -1
                this.navList.forEach((item, index) => {
                    if(path == item.url) {
                        this.curIndex = index
                    }
                })
            },
            
            getCurLang() {
                this.curLang = this.langList[0].value
                this.langList.forEach(item => {
                    if(item.key == localStorage.getItem('lang')) {
                        this.curLang = item.value
                    }
                })
            },
            
            to(path) {
                if(this.curUrl == path) {
                    return
                } else {
                    this.$router.push({
                        path
                    })
                }
            },
            
            changeLang(lang) {
                // console.log(lang)
                localStorage.setItem('lang', lang)
                location.reload()
            },
            
            logout() {
                this.$store.commit('logout')
                setTimeout(() => {
                    this.to('/login')
                },800)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        position: sticky;
        top: 0;
        z-index: 20;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: $header-height;
        box-sizing: border-box;
        padding: 0 $header-footer-padding;
        background-color: #000000;

        .left {
            display: flex;
            align-items: center;

            .logo {
                height: 32px;
                margin-right: 25px;
                cursor: pointer;
            }

            .nav {
                display: flex;
                align-items: center;

                .nav-item {
                    font-size: 14px;
                    color: #fafafa;
                    white-space: nowrap;
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-right: 25px;
                    }
                    
                    &:hover {
                        color: #5466EF;
                    }
                }

                .active {
                    color: #5466EF !important;
                }
            }
        }
        
        .right {
            display: flex;
            align-items: center;
            
            .operate {
                display: flex;
                align-items: center;
                
                .item {
                    font-size: 14px;
                    color: #fafafa;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    &:not(:last-child) {
                        margin-right: 12px;
                    }
                    
                    &:hover {
                        color: #5466EF;
                    }
                }
                
                .active {
                    color: #5466EF !important;
                }
            }
            
            .divide-line {
                min-width: 1px;
                max-width: 1px;
                height: 20px;
                margin: 0 24px;
                background-color: #fafafa;
            }
            
            .el-dropdown-link {
                font-size: 14px;
                color: #fafafa;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
</style>
