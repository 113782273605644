<template>
    <div class="pc-container">
        <div class="main">
            <div class="box">
                <div class="title">登录历史</div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="create_time" label="时间">
                    </el-table-column>
                    <el-table-column prop="type" label="登录类型">
                    </el-table-column>
                    <el-table-column prop="ip" label="IP">
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                    </el-table-column>
                </el-table>
                <div class="pagination-box">
                    <el-pagination @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="11"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="total, prev, pager, next, jumper"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                recordList: [
                    {
                        create_time: '2023-02-22 14:00:00',
                        type: '-',
                        ip: '192.168.1.36',
                        status: '登录成功'
                    },
                    {
                        create_time: '2023-02-22 14:00:00',
                        type: '-',
                        ip: '192.168.1.36',
                        status: '登录成功'
                    },
                    {
                        create_time: '2023-02-22 14:00:00',
                        type: '-',
                        ip: '192.168.1.36',
                        status: '登录成功'
                    },
                    {
                        create_time: '2023-02-22 14:00:00',
                        type: '-',
                        ip: '192.168.1.36',
                        status: '登录成功'
                    }
                ],
                currentPage: 1,
                pageSize: 5,
                total: 8
            }
        },
        methods: {
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 40px;
        background-color: #f6f6f6;
        
        .main {
            width: $main-width;
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 32px 32px 50px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 28px;
                    font-size: 17px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-table th.is-leaf {
                    padding: 12px 0;
                    background-color: #ECEEF8;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        font-weight: normal;
                        color: #6D6F7E;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 16px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table__empty-block {
                    min-height: 100px;
                
                    .el-table__empty-text {
                        line-height: 100px;
                        font-size: 15px;
                    }
                }
                
                /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                    background-color: #F3F5FF;
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 32px 0 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #5466EF;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>