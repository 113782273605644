<template>
    <div class="form-wrap">
        <div class="operate-btns">
            <div class="btn buyup" @click="openForm(1)">{{$t('PerpetualContract.OpenLong')}}</div>
            <div class="btn buydown" @click="openForm(-1)">{{$t('PerpetualContract.OpenShort')}}</div>
        </div>
        <div v-if="showMenuModal" class="mask" @click.stop="showMenuModal = false">
            <div v-if="buyType == 1" id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showMenuModal = true">
                <div class="order-type">
                    <div class="label">{{$t('PerpetualContract.OrderType')}}</div>
                    <div class="type-list">
                        <div v-for="(item,index) in typeList" :key="index"
                            :class="['type-item', typeIndex1 == index ? 'type-item-active': '']"
                            @click="changeTypeIndex1(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Price')}} ({{curCoin.name}})</div>
                    <div class="num">{{parseFloat(curCoin.price.close)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Quantity')}} (USDT)</div>
                    <el-input class="quantity-input" v-model="quantity1" type="number" :placeholder="$t('PerpetualContract.EnterQuantity')"></el-input>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.HandlingFee')}} (USDT)</div>
                    <div class="num">{{handlingFee1}}</div>
                </div>
                <div class="balance">{{$t('PerpetualContract.AvailableBalance')}}: {{balance}}USDT</div>
                <div class="progress-bar-box">
                    <div class="progress-bar">
                        <div v-for="(item,index) in progressBars" :key="index"
                            :class="['progress-bar-item', progressIndex1 >= index ? 'progress-bar-item-active1' : '']"
                            @click="changeProgressIndex1(index)">
                     	  </div>
                    </div>
                    <div class="progress-value">
                        <div v-for="(item,index) in progressBars" :key="index" class="item">{{item}}%</div>
                    </div>
                </div>
                <div class="other">
                    <div class="left">
                        <div class="label">{{$t('PerpetualContract.Lever')}}</div>
                        <div class="lever-list">
                            <div v-for="(item,index) in leverList" :key="index"
                                :class="['lever-item', leverIndex1 == index ? 'lever-item-active' : '']"
                                @click="changeLeverIndex1(index)">
                                {{item}}X
                            </div>
                        </div>
                    </div>
                    <div class="fee">{{$t('PerpetualContract.Fee')}}: {{fee}}%</div>
                </div>
                <div class="buy-btn buyup" @click="openModal(1)">{{$t('PerpetualContract.OpenLong')}}</div>
            </div>
            <div v-else id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showMenuModal = true">
                <div class="order-type">
                    <div class="label">{{$t('PerpetualContract.OrderType')}}</div>
                    <div class="type-list">
                        <div v-for="(item,index) in typeList" :key="index"
                            :class="['type-item', typeIndex2 == index ? 'type-item-active': '']"
                            @click="changeTypeIndex2(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Price')}} ({{curCoin.name}})</div>
                    <div class="num">{{parseFloat(curCoin.price.close)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Quantity')}} (USDT)</div>
                    <el-input class="quantity-input" v-model="quantity2" type="number" :placeholder="$t('PerpetualContract.EnterQuantity')"></el-input>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.HandlingFee')}} (USDT)</div>
                    <div class="num">{{handlingFee2}}</div>
                </div>
                <div class="balance">{{$t('PerpetualContract.AvailableBalance')}}: {{balance}}USDT</div>
                <div class="progress-bar-box">
                    <div class="progress-bar">
                        <div v-for="(item,index) in progressBars" :key="index"
                            :class="['progress-bar-item', progressIndex2 >= index ? 'progress-bar-item-active2' : '']"
                            @click="changeProgressIndex2(index)">
                        </div>
                    </div>
                    <div class="progress-value">
                        <div v-for="(item,index) in progressBars" :key="index" class="item">{{item}}%</div>
                    </div>
                </div>
                <div class="other">
                    <div class="left">
                        <div class="label">{{$t('PerpetualContract.Lever')}}</div>
                        <div class="lever-list">
                            <div v-for="(item,index) in leverList" :key="index"
                                :class="['lever-item', leverIndex2 == index ? 'lever-item-active' : '']"
                                @click="changeLeverIndex2(index)">
                                {{item}}X
                            </div>
                        </div>
                    </div>
                    <div class="fee">{{$t('PerpetualContract.Fee')}}: {{fee}}%</div>
                </div>
                <div class="buy-btn buydown" @click="openModal(-1)">{{$t('PerpetualContract.OpenShort')}}</div>
            </div>
        </div>
        <transition name="slide">
            <div v-if="showModal" class="confirm-mask">
                <div class="modal">
                    <div class="modal-title">{{$t('PerpetualContract.ConfirmOrder')}}</div>
                    <div class="info-list">
                        <div class="info-item">
                            <div class="label">{{$t('Trade.Currency')}}:</div>
                            <div class="value">{{curCoin.name}}/USDT</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Trade.type')}}:</div>
                            <div class="value" :style="{ color: curDirection == 1 ? '#01BD8B' : '#FB474E' }">{{curDirection == 1 ? $t('PerpetualContract.OpenLong') : $t('PerpetualContract.OpenShort')}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Trade.Quantity')}}:</div>
                            <div class="value">{{curDirection == 1 ? quantity1 || 0 : quantity2 || 0}}USDT</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.Lever')}}:</div>
                            <div class="value">{{curDirection == 1 ? leverList[leverIndex1] : leverList[leverIndex2]}}X</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.Margin')}}:</div>
                            <div class="value">{{curDirection == 1 ? margin1 : margin2}}USDT</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.HandlingFee')}}:</div>
                            <div class="value">{{curDirection == 1 ? handlingFee1 : handlingFee2}}USDT</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.TakeProfitPrice')}}:</div>
                            <el-input-number v-model="profitPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.StopPrice')}}:</div>
                            <el-input-number v-model="stopPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                    </div>
                    <div class="btns">
                        <el-button class="cancel-btn" @click="closeModal">{{$t('Common.Cancel')}}</el-button>
                        <el-button class="confirm-btn" @click="submit">{{$t('Common.Confirm')}}</el-button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showMenuModal: false,
                buyType: 1,
                typeList: [this.$t('PerpetualContract.Market')],
                typeIndex1: 0,
                typeIndex2: 0,
                quantity1: '',
                quantity2: '',
                balance: 0,
                progressBars: [25, 50, 75, 100],
                progressIndex1: -1,
                progressIndex2: -1,
                leverList: [25, 50, 100],
                leverIndex1: 2,
                leverIndex2: 2,
                fee: 0.06,
                curProgress1: 1,
                curProgress2: 1,
                curLever1: 100,
                curLever2: 100,
                showModal: false,
                profitPrice: 0,
                stopPrice: 0,
                curDirection: 1
            }
        },
        created() {
            this.getBalance()
			this.getFee()
        },
        mounted() {
            this.$bus.$off('updatePerpetualBalance')
            this.$bus.$on('updatePerpetualBalance', () => {
                this.getBalance()
            })
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
            
            handlingFee1() {
                return (this.quantity1 * this.curLever1 * (this.fee / 100)).toFixed(8)
            },
            
            handlingFee2() {
                return (this.quantity2 * this.curLever2 * (this.fee / 100)).toFixed(8)
            },
            
            margin1() {
                return (this.quantity1 - parseFloat(this.handlingFee1)).toFixed(8)
            },
            
            margin2() {
                return (this.quantity2 - parseFloat(this.handlingFee2)).toFixed(8)
            }
        },
        methods: {
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
			
			getFee() {
				this.$api.setting({
				    slug: 'perpetual_fee'
				},300000).then(res => {
				    if(res.data) {
				        this.fee = parseFloat(res.data) * 100
				    }
				})
			},
            
            openForm(type) {
                this.buyType = type
                this.showMenuModal = true
            },
            
            changeTypeIndex1(index) {
                this.typeIndex1 = index
            },

            changeTypeIndex2(index) {
                this.typeIndex2 = index
            },
            
            input1() {
                setTimeout(() => {
                	this.$set(this,'quantity1',parseInt(this.quantity1))
                },100)
            },
            
            input2() {
                setTimeout(() => {
                	this.$set(this,'quantity2',parseInt(this.quantity2))
                },100)
            },

            changeProgressIndex1(index) {
                if (this.progressIndex1 == index) {
                    this.progressIndex1 = -1
                    this.curProgress1 = 1
                } else {
                    this.progressIndex1 = index
                    this.curProgress1 = this.progressBars[this.progressIndex1] / 100
                    this.changeQuantity1()
                }
            },

            changeProgressIndex2(index) {
                if (this.progressIndex2 == index) {
                    this.progressIndex2 = -1
                    this.curProgress2 = 1
                } else {
                    this.progressIndex2 = index
                    this.curProgress2 = this.progressBars[this.progressIndex2] / 100
                    this.changeQuantity2()
                }
            },

            changeLeverIndex1(index) {
                if(this.leverIndex1 != index) {
                    this.leverIndex1 = index
                    this.curLever1 = this.leverList[this.leverIndex1]
                }
            },

            changeLeverIndex2(index) {
                if(this.leverIndex2 != index) {
                    this.leverIndex2 = index
                    this.curLever2 = this.leverList[this.leverIndex2]
                }
            },
            
            changeQuantity1() {
                this.quantity1 = this.balance * this.curProgress1
            },
            
            changeQuantity2() {
                this.quantity2 = this.balance * this.curProgress2
            },
            
            openModal(direction) {
                this.curDirection = direction
                this.showModal = true
            },
            
            closeModal() {
                this.showModal = false
                this.profitPrice = 0
                this.stopPrice = 0
            },

            submit() {
                this.showModal = false
                let loading = this.$loading.service({
                    target: '#formWrap',
                    text: this.$t('Common.Loading')
                })
                this.$api.perpetualOrderCreate({
                    product_id: this.curCoin.id,
                    direction: this.curDirection,
                    level: this.curDirection == 1 ? this.curLever1 : this.curLever2,
                    number: this.curDirection == 1 ? this.quantity1 : this.quantity2,
                    max_limit: this.profitPrice,
                    stop_limit: this.stopPrice
                }).then(res => {
                    loading.close()
                    this.profitPrice = 0
                    this.stopPrice = 0
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.showMenuModal = false
                    this.getBalance()
                    this.$bus.$emit('updatePerpetualOrder')
                }).catch(err => {
                    loading.close()
                    if(err.code == 405) {
                        this.$router.push({ path: '/h5/account/ChangeTransactionPassword/0' })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap {
        position: fixed;
        bottom: 6rem;
        left: 0;
        z-index: 20;
        width: 100%;
        
        .operate-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 7rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            border-bottom: 1px solid #E9E9E9;
            background-color: #FFFFFF;
            
            .btn {
                width: 48%;
                height: 4.3rem;
                line-height: 4.3rem;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            
            .buyup {
                background-color: #01BD8B;
            }
            
            .buydown {
                background-color: #FB474E;
            }
        }
        
        .mask {
            position: fixed;
            bottom: 6rem;
            left: 0;
            z-index: 40;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: calc(100vh - 6rem);
            background-color: rgba(0,0,0,.4);
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.6rem 2.4rem;
                border-bottom: 1px solid #E9E9E9;
                background-color: #FFFFFF;
                animation-duration: 500ms;
            
                .order-type {
                    display: flex;
                    align-items: center;
                    width: 100%;
            
                    .label {
                        margin-right: 1.6rem;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        white-space: nowrap;
                    }
            
                    .type-list {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
            
                        .type-item {
                            height: 2.5rem;
                            line-height: 2.5rem;
                            box-sizing: border-box;
                            padding: 0 1rem;
                            border: 1px solid #5466EF;
                            border-radius: 4px;
                            font-size: 1.2rem;
                            color: #5466EF;
                            text-align: center;
                            background-color: #FFFFFF;
                            cursor: pointer;
            
                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                        }
            
                        .type-item-active {
                            color: #FFFFFF !important;
                            background-color: #5466EF !important;
                        }
                    }
                }
            
                .info-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 4.5rem;
                    box-sizing: border-box;
                    padding: 0 1.2rem;
                    border: 1px solid #E7E9F1;
                    border-radius: 6px;
                    margin-top: 1.4rem;
            
                    .label {
                        margin-right: 2rem;
                        font-size: 1.2rem;
                        color: #383942;
                        white-space: nowrap;
                    }
            
                    .num {
                        max-width: 100%;
                        font-size: 1.4rem;
                        color: #000000;
                        word-break: break-all;
                    }
            
                    .quantity-input {
                        width: 100%;
            
                        /deep/ .el-input__inner {
                            height: 3.6rem;
                            line-height: 3.6rem;
                            padding: 0;
                            border: 0;
                            font-size: 1.4rem;
                            color: #000000;
                            text-align: right;
                        }
                    }
                }
            
                .balance {
                    width: 100%;
                    margin: 1.8rem 0 1.4rem;
                    font-size: 1.2rem;
                    color: #6D6F7E;
                    word-break: break-all;
                }
            
                .progress-bar-box {
                    width: 100%;
                    margin-bottom: 1.8rem;
            
                    .progress-bar {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                        height: 1.2rem;
                        box-sizing: border-box;
                        margin-bottom: 4px;
                        border-radius: 1.2rem;
                        background-color: #F4F4F4;
                        cursor: pointer;
            
                        .progress-bar-item {
                            display: flex;
                            justify-content: center;
                           	align-items: center;
                            width: 25%;
                            height: 100%;
                            box-sizing: border-box;
            
                            &:not(:last-child) {
                                border-right: 1px solid #E7E9F1;
                            }
                        }
            
                        .progress-bar-item-active1 {
                            color: #FFFFFF !important;
                            background-color: #01BD8B;
            
                            &:first-child {
                                border-radius: 1.2rem 0 0 1.2rem;
                            }
            
                            &:last-child {
                                border-radius: 0 1.2rem 1.2rem 0;
                            }
                        }
            
                        .progress-bar-item-active2 {
                            color: #FFFFFF !important;
                            background-color: #FB474E;
            
                            &:first-child {
                                border-radius: 1.2rem 0 0 1.2rem;
                            }
            
                            &:last-child {
                                border-radius: 0 1.2rem 1.2rem 0;
                            }
                        }
                    }
            
                    .progress-value {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
            
                        .item {
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            white-space: nowrap;
                        }
                    }
                }
            
                .other {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 4rem;
            
                    .left {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                        margin-right: 2rem;
            
                        .label {
                            margin-right: 1.6rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            white-space: nowrap;
                        }
            
                        .lever-list {
                            display: flex;
                            align-items: center;
                            max-width: 100%;
            
                            .lever-item {
                                width: 4.8rem;
                                height: 2.8rem;
                                line-height: 2.8rem;
                                box-sizing: border-box;
                                border: 1px solid #5466EF;
                                border-radius: 4px;
                                font-size: 1.2rem;
                                color: #5466EF;
                                text-align: center;
                                background-color: #FFFFFF;
                                cursor: pointer;
            
                                &:not(:last-child) {
                                    margin-right: 5px;
                                }
                            }
            
                            .lever-item-active {
                                color: #FFFFFF !important;
                                background-color: #5466EF !important;
                            }
                        }
                    }
            
                    .fee {
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        white-space: nowrap;
                    }
                }
            
                .buy-btn {
                    width: 100%;
                    height: 4.5rem;
                    line-height: 4.5rem;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
            
                .buyup {
                    background-color: #01BD8B;
                }
            
                .buydown {
                    background-color: #FB474E;
                }
            }
        }
        
        .confirm-mask {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            box-sizing: border-box;
            padding: 0 1.6rem;
            background-color: rgba(0, 0, 0, .4);
            
            .modal {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 2rem 2.4rem;
                border-radius: 8px;
                background-color: #FFFFFF;
                
                .modal-title {
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .info-list {
                    width: 100%;
                    
                    .info-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.4rem;
                        }
                        
                        .label {
                            max-width: 48%;
                            font-size: 1.3rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 48%;
                            font-size: 1.4rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                        
                        /deep/ .el-input--small {
                            font-size: 12px;
                        }
                    }
                }
                
                .btns {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 2.4rem;
                    
                    .cancel-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 1px solid #6D6F7E;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #6D6F7E;
                        text-align: center;
                        background-color: #FFFFFF;
                    }
                    
                    .confirm-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 0;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                    }
                }
            }
        }
    }
</style>
