<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Assets.Transfer')" />
        <div class="main">
            <div class="box1">
                <el-form ref="form" :model="form" :rules="rules">
                    <div class="info-wrap">
                        <div class="info-item">
                            <div class="label">{{$t('Assets.SelectCurrency')}}</div>
                            <el-select v-model="form.from" class="select-box" placeholder="">
                                <el-option v-for="(item,index) in fromCoins" :key="index" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="balance">
                            {{$t('Assets.Frozen')}}: {{frozen}}USDT
                            <br/>
                            {{$t('Assets.AvailableBalance')}}: {{balance}}USDT
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.ExchangeCurrency')}}</div>
                            <el-select v-model="form.to" class="select-box" placeholder="">
                                <el-option v-for="(item,index) in toCoins" :key="index" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.ExchangeAmount')}}</div>
                            <el-form-item prop="from_amount">
                                <el-input v-model="form.from_amount" class="form-input" placeholder=""></el-input>
                            </el-form-item>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.ExchangeRate')}}</div>
                            <div class="value">{{exchangeRate}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.Expected')}}</div>
                            <div class="value">{{expectedAmount}} {{form.to}}</div>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.TransactionPassword')}}</div>
                            <el-form-item v-if="userInfo.is_pay_pass" prop="pay_pass">
                                <el-input v-model="form.pay_pass" show-password class="form-input" placeholder=""></el-input>
                            </el-form-item>
                            <div v-else class="set-btn" @click="to('/h5/account/ChangeTransactionPassword/0')">{{$t('Assets.SetTransactionPassword')}}</div>
                        </div>
                        <el-button :loading="loading" class="confirm-btn" @click="transfer">{{$t('Common.Confirm')}}</el-button>
                    </div>
                </el-form>
            </div>
            <div class="box2">
                <div class="title">{{$t('Assets.TransferRecord')}}</div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="created_at" :label="$t('Assets.Time')">
                    </el-table-column>
                    <el-table-column prop="from" :label="$t('Assets.CurrencyTransfer')">
                    </el-table-column>
                    <el-table-column prop="to" :label="$t('Assets.ExchangeCurrency')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Quantity')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.from_amount)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Status')">
                        <template slot-scope="scope">
                            <span style="color: #01BD8B;">{{$t('Assets.Success')}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="recordList.length > 0" class="pagination-box">
                    <el-pagination small @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="5"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="prev, pager, next"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            return {
                form: {
                    from: 'USDT',
                    to: 'BTC',
                    from_amount: '',
                    pay_pass: ''
                },
                rules: {
                    from_amount: [{
                        required: true,
                        message: this.$t('Assets.EnterExchangeAmount'),
                        trigger: 'blur'
                    }],
                    pay_pass: [{
                        required: true,
                        message: this.$t('Assets.EnterTransactionPassword'),
                        trigger: 'blur'
                    }]
                },
                userInfo: {
                    is_pay_pass: false
                },
                frozen: 0,
                balance: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                loading: false
            }
        },
        created() {
            let coin_name = this.$route.query.coin_name
            if(coin_name) {
                this.form.from = coin_name
            }
            this.getBalance()
            this.getFrozen()
            this.getUserInfo()
            this.getRecordList(true)
            this.$store.dispatch('getProduct')
        },
        mounted() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        activated() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            transferCoins() {
                return this.$store.state.transferCoins
            },
            
            fromCoins() {
            	return JSON.parse(JSON.stringify(this.transferCoins))
            },
            
            toCoins() {
            	if(this.form.from == 'USDT') {
            		return this.transferCoins.filter(item => item != this.form.from)
            	} else {
            		return this.transferCoins.filter(item => item == 'USDT')
            	}
            },
            
            exchangeRate() {
                if(this.form.from == 'USDT' || this.form.from == 'USDC') {
                    if(this.form.to == 'USDT' || this.form.to == 'USDC') {
                        return 1
                    } else {
                        let coin = this.productList.find(item => item.name == this.form.to)
                        return coin ? (1 / parseFloat(coin.price.close)).toFixed(8) : 0
                    }
                } else {
                    let coin = this.productList.find(item => item.name == this.form.from)
                    return coin ? parseFloat(coin.price.close).toFixed(8) : 0
                }
            },
            
            expectedAmount() {
                let num = this.form.from_amount ? parseFloat(this.form.from_amount) : 0
                return (num * this.exchangeRate).toFixed(8)
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            },
            
            toCoins(val) {
                this.form.to = val[0]
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
            
            getFrozen() {
                this.$api.frozen().then(res => {
                    this.frozen = parseFloat(res.data)
                })
            },
            
            getUserInfo() {
                this.$api.user_info().then(res => {
                    this.userInfo = res.data
                })
            },
            
            transfer() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.tradeOrderCreate(this.form).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            this.form.pay_pass = ''
                            this.getBalance()
                            this.getFrozen()
                            this.getRecordList(true)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            },
            
            getRecordList(bool=false) {
                this.$api.tradeOrderRecord({
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 2rem;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                margin-bottom: 1.2rem;
                background-color: #FFFFFF;
                
                .info-wrap {
                    width: 100%;
                    
                    .info-item {
                        width: 100%;
                        margin-bottom: 1.6rem;
                        cursor: default;
                        
                        &:first-child {
                            margin-bottom: 0;
                        }
                        
                        .label {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .select-box {
                            width: 100%;
                            
                            /deep/ .el-input__prefix {
                                position: absolute;
                                top: 50%;
                                right: 3.8rem;
                                transform: translateY(-50%);
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                font-size: 1.2rem;
                                color: #6D6F7E;
                                white-space: nowrap;
                            }
                            
                            /deep/ .el-input__inner {
                                height: 4.2rem;
                                line-height: 4.2rem;
                                padding: 0 3.4rem 0 1.6rem;
                                border: 0;
                                font-size: 1.2rem;
                                color: #000000;
                                background-color: #F6F7FA;
                            }
                        }
                        
                        .value {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 4.2rem;
                            box-sizing: border-box;
                            padding: 0 1.6rem;
                            border-radius: 4px;
                            font-size: 1.2rem;
                            color: #000000;
                            word-break: break-all;
                            background-color: #F6F7FA;
                        }
                        
                        .form-input {
                            width: 100%;
                            
                            /deep/ .el-input__inner {
                                height: 4.2rem;
                                line-height: 4.2rem;
                                padding: 0 1.6rem;
                                border: 0;
                                border-radius: 4px;
                                font-size: 1.2rem;
                                color: #000000;
                                background-color: #F6F7FA;
                            }
                        }
                        
                        .set-btn {
                            display: inline-block;
                            height: 3.6rem;
                            line-height: 3.6rem;
                            box-sizing: border-box;
                            padding: 0 1.6rem;
                            border-radius: 4px;
                            font-size: 1.2rem;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #5466EF;
                            cursor: pointer;
                        }
                    }
                    
                    .balance {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                        margin: 3px 0 1.6rem;
                        font-size: 1.2rem;
                        color: #A3A6BB;
                        text-align: right;
                        word-break: break-all;
                    }
                    
                    .confirm-btn {
                        width: 100%;
                        height: 4.6rem;
                        box-sizing: border-box;
                        margin-top: 2rem;
                        border: 0;
                        border-radius: 4px;
                        font-size: 1.3rem;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-table th.is-leaf {
                    padding: 6px 0;
                    background-color: #ECEEF8;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        font-weight: normal;
                        color: #6D6F7E;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 8px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table__empty-block {
                    min-height: 100px;
                
                    .el-table__empty-text {
                        line-height: 100px;
                        font-size: 13px;
                    }
                }
                
                /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                    background-color: #F3F5FF;
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1.6rem 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #5466EF;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>