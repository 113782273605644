<template>
    <div class="pc-container">
        <h1>购买加密货币</h1>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
    }
</style>