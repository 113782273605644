<template>
    <div class="form-wrap">
        <div class="operate-btns">
            <div class="btn buyup" @click="openForm(1)">{{$t('CurrencyTrading.Buy')}}</div>
            <div class="btn buydown" @click="openForm(-1)">{{$t('CurrencyTrading.Sell')}}</div>
        </div>
        <div v-if="showModal" class="mask" @click.stop="showModal = false">
            <div v-if="buyType == 1" id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showModal = true">
                <div class="box-title">{{$t('CurrencyTrading.Buy')}} {{curCoin.name}}</div>
                <div class="balance">{{$t('CurrencyTrading.MyBalance')}}: {{usdtBalance}}USDT</div>
                <div class="can-buy">{{$t('CurrencyTrading.AvailablePurchaseAmount')}}: {{(parseFloat(usdtBalance) / parseFloat(curCoin.price.close)).toFixed(curCoin.priceDecimal)}}{{curCoin.name}}</div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.CurrentPrice')}} ({{curCoin.name}})</div>
                    <div class="num">{{parseFloat(curCoin.price.close)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.BuyQuantity')}} ({{curCoin.name}})</div>
                    <el-input class="quantity-input" v-model="quantity1" type="number" :placeholder="$t('CurrencyTrading.EnterBuyQuantity')" @input="progressIndex1 = -1"></el-input>
                </div>
                <div class="progress-bar-box">
                    <div class="progress-bar">
                        <div v-for="(item,index) in progressBars" :key="index"
                            :class="['progress-bar-item', progressIndex1 >= index ? 'progress-bar-item-active1' : '']"
                            @click="changeProgressIndex1(index,item)">
                     	  </div>
                    </div>
                    <div class="progress-value">
                        <div v-for="(item,index) in progressBars" :key="index" class="item">{{item}}%</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.RequiredAmount')}} (USDT)</div>
                    <div class="num">{{total1}}</div>
                </div>
                <div class="buy-btn buyup" @click="buy(1)">{{$t('CurrencyTrading.Buy')}}</div>
            </div>
            <div v-else id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showModal = true">
                <div class="box-title">{{$t('CurrencyTrading.Sell')}} {{curCoin.name}}</div>
                <div class="balance">{{$t('CurrencyTrading.MyBalance')}}: {{coinBalance}}{{curCoin.name}}</div>
                <div class="can-buy">{{$t('CurrencyTrading.ConvertibleAmount')}}: {{(coinBalance * parseFloat(curCoin.price.close)).toFixed(8)}}USDT</div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.CurrentPrice')}} ({{curCoin.name}})</div>
                    <div class="num">{{parseFloat(curCoin.price.close)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.SellQuantity')}} ({{curCoin.name}})</div>
                    <el-input class="quantity-input" v-model="quantity2" type="number" :placeholder="$t('CurrencyTrading.EnterSellQuantity')" @input="progressIndex2 = -1"></el-input>
                </div>
                <div class="progress-bar-box">
                    <div class="progress-bar">
                        <div v-for="(item,index) in progressBars" :key="index"
                            :class="['progress-bar-item', progressIndex2 >= index ? 'progress-bar-item-active2' : '']"
                            @click="changeProgressIndex2(index,item)">
                        </div>
                    </div>
                    <div class="progress-value">
                        <div v-for="(item,index) in progressBars" :key="index" class="item">{{item}}%</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.ExchangeAmount')}} (USDT)</div>
                    <div class="num">{{total2}}</div>
                </div>
                <div class="buy-btn buydown" @click="buy(-1)">{{$t('CurrencyTrading.Sell')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
                buyType: 1,
                banlanceList: [],
                quantity1: '',
                quantity2: '',
                progressBars: [25, 50, 75, 100],
                progressIndex1: -1,
                progressIndex2: -1
            }
        },
        created() {
            this.getBalance()
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
            
            total1() {
            	return (parseFloat(this.quantity1 || 0) * parseFloat(this.curCoin.price.close)).toFixed(8);
            },
            
            total2() {
            	return (parseFloat(this.quantity2 || 0) * parseFloat(this.curCoin.price.close)).toFixed(8);
            },
            
            usdtBalance() {
                let usdt = this.banlanceList.find(item => item.type == 'USDT') || { type: 'USDT', num: 0 }
                return parseFloat(usdt.num)
            },
            
            coinBalance() {
                let cur_coin = this.banlanceList.find(item => item.type == this.curCoin.name) || { type: '', num: 0 }
                return parseFloat(cur_coin.num)
            }
        },
        methods: {
            getBalance() {
                this.$api.credit().then(res => {
                    this.banlanceList = res.data
                })
            },
            
            openForm(type) {
                this.buyType = type
                this.showModal = true
            },
            
            changeProgressIndex1(index,item) {
                if (this.progressIndex1 == index) {
                    this.progressIndex1 = -1
                } else {
                    this.progressIndex1 = index
                }
                
                if(this.progressIndex1 >= 0) {
                    this.quantity1 = (parseFloat(this.usdtBalance) / parseFloat(this.curCoin.price.close) * (item / 100)).toFixed(this.curCoin.priceDecimal)
                }
            },

            changeProgressIndex2(index,item) {
                if (this.progressIndex2 == index) {
                    this.progressIndex2 = -1
                } else {
                    this.progressIndex2 = index
                }
                
                if(this.progressIndex2 >= 0) {
                    this.quantity2 = (this.coinBalance * (item / 100)).toFixed(this.curCoin.priceDecimal)
                }
            },

            buy(direction) {
                this.$prompt(this.$t('Trade.EnterPayPass'), '', {
                    customClass: "prompt-box-h5",
                    confirmButtonText: this.$t('Common.Confirm'),
                    cancelButtonText: this.$t('Common.Cancel'),
                    confirmButtonClass: 'prompt-confirm-btn',
                    cancelButtonClass: 'prompt-cancel-btn',
                    showClose: false,
                    closeOnClickModal: false,
                    inputType: 'password',
                    inputPattern: /[^\s]/,
                    inputErrorMessage: this.$t('Trade.EnterPayPass')
                }).then(({ value }) => {
                    // console.log(value)
                    let loading = this.$loading.service({
                        target: '#formWrap',
                        text: this.$t('Common.Loading')
                    })
                    let params = {
                        from: direction == 1 ? 'USDT' : this.curCoin.name,
                        to: direction == 1 ? this.curCoin.name : 'USDT',
                        pay_pass: value
                    }
                    if(direction == 1) {
                        params.to_amount = this.quantity1
                    } else {
                        params.from_amount = this.quantity2
                    }
                    
                    this.$api.tradeOrderCreate(params).then(res => {
                        loading.close()
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 1500
                        })
                        this.showModal = false
                        this.getBalance()
                        this.$bus.$emit('updateTradeOrder')
                    }).catch(err => {
                        loading.close()
                        if(err.code == 405) {
                            this.$router.push({ path: '/h5/account/ChangeTransactionPassword/0' })
                        }
                    })
                }).catch(() => {

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap {
        position: fixed;
        bottom: 6rem;
        left: 0;
        z-index: 20;
        width: 100%;
        
        .operate-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 7rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            border-bottom: 1px solid #E9E9E9;
            background-color: #FFFFFF;
            
            .btn {
                width: 48%;
                height: 4.3rem;
                line-height: 4.3rem;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            
            .buyup {
                background-color: #01BD8B;
            }
            
            .buydown {
                background-color: #FB474E;
            }
        }
        
        .mask {
            position: fixed;
            bottom: 6rem;
            left: 0;
            z-index: 40;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: calc(100vh - 6rem);
            background-color: rgba(0,0,0,.4);
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.6rem 2.4rem;
                border-bottom: 1px solid #E9E9E9;
                background-color: #FFFFFF;
                animation-duration: 500ms;
            
                .box-title {
                    width: 100%;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                .balance {
                    width: 100%;
                    margin: 1.6rem 0 0.6rem;
                    font-size: 1.2rem;
                    color: #6D6F7E;
                    word-break: break-all;
                }
                
                .can-buy {
                    width: 100%;
                    font-size: 1.2rem;
                    color: #6D6F7E;
                    word-break: break-all;
                }
                
                .info-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 4.5rem;
                    box-sizing: border-box;
                    padding: 0 1.2rem;
                    border: 1px solid #E7E9F1;
                    border-radius: 6px;
                    margin-top: 1.4rem;
                
                    .label {
                        margin-right: 2rem;
                        font-size: 1.2rem;
                        color: #383942;
                        white-space: nowrap;
                    }
                
                    .num {
                        max-width: 100%;
                        font-size: 1.4rem;
                        color: #000000;
                        word-break: break-all;
                    }
                
                    .quantity-input {
                        width: 100%;
                
                        /deep/ .el-input__inner {
                            height: 3.6rem;
                            line-height: 3.6rem;
                            padding: 0;
                            border: 0;
                            font-size: 1.4rem;
                            color: #000000;
                            text-align: right;
                        }
                    }
                }
                
                .progress-bar-box {
                    width: 100%;
                    margin-top: 1.4rem;
                
                    .progress-bar {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                        height: 1.2rem;
                        box-sizing: border-box;
                        margin-bottom: 4px;
                        border-radius: 1.2rem;
                        background-color: #F4F4F4;
                        cursor: pointer;
                
                        .progress-bar-item {
                            display: flex;
                            justify-content: center;
                           	align-items: center;
                            width: 25%;
                            height: 100%;
                            box-sizing: border-box;
                
                            &:not(:last-child) {
                                border-right: 1px solid #E7E9F1;
                            }
                        }
                
                        .progress-bar-item-active1 {
                            color: #FFFFFF !important;
                            background-color: #01BD8B;
                
                            &:first-child {
                                border-radius: 1.2rem 0 0 1.2rem;
                            }
                
                            &:last-child {
                                border-radius: 0 1.2rem 1.2rem 0;
                            }
                        }
                
                        .progress-bar-item-active2 {
                            color: #FFFFFF !important;
                            background-color: #FB474E;
                
                            &:first-child {
                                border-radius: 1.2rem 0 0 1.2rem;
                            }
                
                            &:last-child {
                                border-radius: 0 1.2rem 1.2rem 0;
                            }
                        }
                    }
                
                    .progress-value {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                
                        .item {
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            white-space: nowrap;
                        }
                    }
                }
                
                .buy-btn {
                    width: 100%;
                    height: 4.5rem;
                    line-height: 4.5rem;
                    box-sizing: border-box;
                    margin-top: 4rem;
                    border-radius: 2px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
                
                .buyup {
                    background-color: #01BD8B;
                }
                
                .buydown {
                    background-color: #FB474E;
                }
            }
        }
    }
</style>
