<template>
    <div class="trading-top">
        <div class="cur-currency">
            <div class="currency item">
                <img class="icon" :src="curCoin.pic" />
                <div class="name">{{curCoin.name + '/USDT'}}</div>
                <img class="star" src="@/assets/images/pc/trade/star-on.png" />
            </div>
            <div class="other item">
                <div class="close" :style="{ color: parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '#01BD8B' : '#FB474E'}">{{parseFloat(curCoin.price.close)}}</div>
                <div class="valuation">≈ {{parseFloat(curCoin.price.close)}}$</div>
            </div>
            <div class="other item">
                <div class="label">{{$t('Trade.Change')}}</div>
                <div class="value" :style="{ color: parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '#01BD8B' : '#FB474E'}">
                    {{parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '+' : ''}}{{((parseFloat(curCoin.price.close) - parseFloat(curCoin.price.open))/ parseFloat(curCoin.price.open) * 100).toFixed(2)}}%
                </div>
            </div>
            <div class="other item">
                <div class="label">{{$t('Trade.Highest')}}</div>
                <div class="value">{{parseFloat(curCoin.price.high)}}</div>
            </div>
            <div class="other item">
                <div class="label">{{$t('Trade.Lowest')}}</div>
                <div class="value">{{parseFloat(curCoin.price.low)}}</div>
            </div>
        </div>
        <el-tabs v-model="tabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane v-for="(item,index) in tabList" :key="item.name" :label="item.title" :name="item.name">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabsValue: '',
                tabList: []
            }
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
        },
        watch: {
            tabsValue(val) {
                this.tabList.forEach(item => {
                    if(val == item.name) {
                        this.$store.commit('setCurCoin',item.coin_info)
                    }
                })
            },
            
            curCoin(val) {
                this.addTab(val)
            }
        },
        methods: {
            addTab(val) {
                let isRepeat = false
                this.tabList.forEach(item => {
                    if(val.key == item.name) {
                        isRepeat = true
                    }
                })
                
                if(!isRepeat) {
                    this.tabList.push({
                        name: val.key,
                        title: val.name,
                        coin_info: val
                    })
                    this.tabsValue = val.key
                } else {
                    this.tabsValue = val.key
                }
            },
            
            removeTab(targetName) {
                // console.log(targetName)
                let tabs = this.tabList
                let activeName = this.tabsValue
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1]
                            if (nextTab) {
                                activeName = nextTab.name
                            }
                        }
                    })
                }

                this.tabsValue = activeName
                this.tabList = tabs.filter(tab => tab.name !== targetName)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trading-top {
        width: 100%;

        .cur-currency {
            display: flex;
            align-items: center;
            width: 100%;
            height: 75px;
            box-sizing: border-box;
            padding: 0 20px;
            margin-bottom: 5px;
            background-color: #FFFFFF;

            .item {
                cursor: default;

                &:not(:last-child) {
                    margin-right: 64px;
                }
            }

            .currency {
                display: flex;
                align-items: center;

                .icon {
                    min-width: 32px;
                    max-width: 32px;
                    height: 32px;
                }

                .name {
                    margin: 0 10px 0 8px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    white-space: nowrap;
                }

                .star {
                    min-width: 16px;
                    max-width: 16px;
                    height: 16px;
                    margin-bottom: 2px;
                }
            }

            .other {
                white-space: nowrap;

                .close {
                    margin-bottom: 2px;
                    font-size: 20px;
                    font-weight: bold;
                }

                .valuation {
                    font-size: 14px;
                    color: #6D6F7E;
                }

                .label {
                    margin-bottom: 6px;
                    font-size: 13px;
                    color: #6D6F7E;
                }

                .value {
                    font-size: 14px;
                    color: #000000;
                }
            }
        }
        
        /deep/ .el-tabs--card>.el-tabs__header {
            border: 0;
            margin: 0;
        }
        
        /deep/ .el-tabs__nav-wrap {
            margin: 0;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
            border: 0;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
            border: 0;
            color: #000000;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
            background-color: #FFFFFF;
            border: 0;
            color: #5466EF;
        }
    }
</style>
