<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Module.Announcement')" />
        <div class="main">
            <div class="list">
                <div v-for="(item,index) in list" :key="index" class="item" @click="toDetails(item.id)">
                    <div class="title">{{item.title}}</div>
                    <i class="el-icon-right"></i>
                </div>
            </div>
            <!-- <div class="pagination-box">
                <el-pagination small @current-change="handleCurrentChange"
                    :current-page="currentPage" :pager-count="11"
                    :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                    :page-size="pageSize" layout="total, prev, pager, next, jumper"
                    :total="total" background>
                </el-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            return {
                list: [],
                curIndex: 0,
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            this.getList()
        },
        methods: {
            toDetails(id) {
                this.$router.push({
                    path: '/h5/announcement/details',
                    query: {
                        id
                    }
                })
            },
            
            getList() {
                this.$api.announcement({
                    withContent: true
                },300000).then(res => {
                    this.list = res.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 1.6rem;
            
            .list {
                width: 100%;
                
                .item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 4.5rem;
                    box-sizing: border-box;
                    padding: 0 1.6rem;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    
                    &:not(:last-child) {
                        margin-bottom: 1.2rem;
                    }
                    
                    .title {
                        max-width: 100%;
                        font-size: 1.4rem;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    
                    .el-icon-right {
                        margin-left: 2rem;
                        font-size: 1.6rem;
                        color: #000000;
                        white-space: nowrap;
                    }
                }
            }
            
            .pagination-box {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 0 0 0;
                
                /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                    background-color: #5466EF;
                }
                
                /deep/ .el-pager li:not(.active):hover {
                    color: #5466EF;
                }
            }
        }
    }
</style>