<template>
    <div class="my-order">
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <el-table :data="recordList" style="width: 100%;">
            <el-table-column prop="created_at" :label="$t('Trade.Time')">
            </el-table-column>
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <span>{{scope.row.type == 2 ? scope.row.to : scope.row.from}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.type')">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.type == 2 ? '#01BD8B' : '#FB474E' }">{{scope.row.type == 2 ? $t('CurrencyTrading.Buy') : $t('CurrencyTrading.Sell')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Price')">
                <template slot-scope="scope">
                    <span>{{scope.row.type == 2 ? parseFloat(scope.row.to_rate) : parseFloat(scope.row.from_rate)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Quantity')">
                <template slot-scope="scope">
                    <span>{{scope.row.type == 2 ? parseFloat(scope.row.to_amount) : parseFloat(scope.row.from_amount)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('CurrencyTrading.Status')">
                <template slot-scope="scope">
                    <span style="color: #01BD8B;">{{$t('CurrencyTrading.Success')}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination small @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="5"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next"
                :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabList: [this.$t('CurrencyTrading.OrderHistory')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updateTradeOrder')
            this.$bus.$on('updateTradeOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        methods: {
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.tradeOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        margin-top: 4px;
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 3.5rem;
                line-height: 3.5rem;
                box-sizing: border-box;
                padding: 0 1.2rem;
                font-size: 1.3rem;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 8px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 8px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 12px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 13px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>