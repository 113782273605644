<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Common.Details')" />
        <div class="main">
            <div class="box">
                <div class="title">{{details.title}}</div>
                <div class="time">{{details.publish_at}}</div>
                <div class="divide-line"></div>
                <div v-html="details.content" class="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            return {
                details: {}
            }
        },
        created() {
            let id = this.$route.query.id
            if(id) {
                this.getDetails(id)
            }
        },
        methods: {
            getDetails(id) {
                this.$api.announcement({
                    id,
                    withContent: true
                }).then(res => {
                    this.details = res.data
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 1.6rem;
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .time {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.3rem;
                    color: #6D6F7E;
                    text-align: right;
                    word-wrap: break-word;
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    background-color: #E9E9E9;
                }
                
                .content {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 2rem 0;
                    word-wrap: break-word;
                }
            }
        }
    }
</style>