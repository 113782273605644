<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Assets.Deposit')" />
        <div class="main">
            <div class="box1">
                <div class="info-wrap">
                    <div class="info-item">
                        <div class="label">{{$t('Assets.SelectCurrency')}}</div>
                        <el-select v-model="currency" placeholder="" @change="change">
                            <el-option v-for="(item,index) in rechargeCoins" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('Assets.Address')}}</div>
                        <div class="value">
                            <div class="address">{{address}}</div>
                            <div class="copy" @click="copy(address)">{{$t('Common.copy')}}</div>
                        </div>
                    </div>
                    <qrcode class="ewm-img" :value="address" :size="180" />
                    <div class="notice">
                        <div class="label">{{$t('Assets.Notice')}}</div>
                        <div v-html="notice" class="content"></div>
                    </div>
                </div>
            </div>
            <div class="box2">
                <div class="title">{{$t('Assets.DepositRecord')}}</div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="created_at" :label="$t('Assets.Time')">
                    </el-table-column>
                    <!-- <el-table-column label="账单类型">
                        <template slot-scope="scope">
                            <span>存款</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="id" :label="$t('Assets.OrderNumber')">
                    </el-table-column>
                    <el-table-column prop="user_credit_type" :label="$t('Assets.Currency')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Quantity')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.num)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Status')">
                        <template slot-scope="scope">
                            <span :style="{color: curStatus(scope.row.status).color}">{{curStatus(scope.row.status).name}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="recordList.length > 0" class="pagination-box">
                    <el-pagination small @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="5"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="prev, pager, next"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue'),
            qrcode: () => import('@/components/qrcode.vue')
        },
        data() {
            return {
                currency: 'BTC',
                address: '',
                notice: '',
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            let coin_name = this.$route.query.coin_name
            if(coin_name) {
                this.currency = coin_name
            }
            this.getAddress()
            this.getNotice()
            this.getRecordList(true)
        },
        computed: {
            rechargeCoins() {
                return this.$store.state.rechargeCoins
            },
            
            curStatus() {
                return (status) => {
                    let obj = {
                        color: '',
                        name: ''
                    }
                    switch(status) {
                        case 0:
                            obj = {
                                color: '#08B5E0',
                                name: this.$t('Assets.Status1')
                            }
                            break
                        case 1:
                            obj = {
                                color: '#01BD8B',
                                name: this.$t('Assets.Status2')
                            }
                            break
                        case -1:
                            obj = {
                                color: '#FB474E',
                                name: this.$t('Assets.Status3')
                            }
                            break
                    }
                    
                    return obj
                }
            }
        },
        methods: {
            copy(text) {
                let input = document.createElement("input")
                input.value = text
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, input.value.length)
                document.execCommand('Copy')
                document.body.removeChild(input)
                this.$message({
                    message: this.$t('Common.CopySuccessfully'),
                    type: 'success',
                    duration: 1500
                })
            },
            
            getAddress() {
                this.$api.get_address({
                    coin: this.currency
                }).then(res => {
                    this.address = res.data.address || ''
                })
            },
            
            getNotice() {
                this.$api.setting_lang({
                    slug: 'recharge_info'
                },300000).then(res => {
                    if(res.data) {
                        this.notice = res.data
                    }
                })
            },
            
            change() {
                this.getAddress()
            },
            
            getRecordList(bool=false) {
                this.$api.recharge_list({
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 2rem;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                margin-bottom: 1.2rem;
                background-color: #FFFFFF;
                
                .info-wrap {
                    width: 100%;
                    
                    .info-item {
                        width: 100%;
                        cursor: default;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.6rem;
                        }
                        
                        .label {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        /deep/ .el-select {
                            width: 100%;
                        }
                        
                        /deep/ .el-input__inner {
                            height: 4.2rem;
                            line-height: 4.2rem;
                            padding: 0 3.4rem 0 1.6rem;
                            border: 0;
                            font-size: 1.2rem;
                            color: #000000;
                            background-color: #F6F7FA;
                        }
                        
                        .value {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 4.2rem;
                            box-sizing: border-box;
                            padding: 0 1.6rem;
                            border-radius: 4px;
                            background-color: #F6F7FA;
                            
                            .address {
                                width: 100%;
                                font-size: 1.2rem;
                                color: #000000;
                                word-break: break-all;
                            }
                            
                            .copy {
                                margin-left: 1.6rem;
                                font-size: 1.2rem;
                                color: #5466EF;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                        }
                    }
                    
                    .ewm-img {
                        display: flex;
                        justify-content: center;
                        margin: 32px auto;
                    }
                    
                    .notice {
                        width: 100%;
                        
                        .label {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .content {
                            width: 100%;
                            word-wrap: break-word;
                        }
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-table th.is-leaf {
                    padding: 6px 0;
                    background-color: #ECEEF8;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        font-weight: normal;
                        color: #6D6F7E;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 8px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table__empty-block {
                    min-height: 100px;
                
                    .el-table__empty-text {
                        line-height: 100px;
                        font-size: 13px;
                    }
                }
                
                /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                    background-color: #F3F5FF;
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1.6rem 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #5466EF;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>