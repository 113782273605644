<template>
    <div class="back-box">
        <span class="back" @click="back">
            <i class="el-icon-back"></i>
            {{$t('Common.Back')}}
        </span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            back() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .back-box {
        width: 100%;
        margin-bottom: 32px;
    
        .back {
            display: inline-flex;
            align-items: center;
            max-width: 100%;
            font-size: 16px;
            color: #000000;
            word-wrap: break-word;
            cursor: pointer;
    
            .el-icon-back {
                margin-right: 8px;
                font-size: 20px;
                white-space: nowrap;
            }
        }
    }
</style>