export default {
    "Module": {
        "Home": "홈페이지",
        "PerpetualContract": "무기한 계약",
        "CurrencyTrading": "통화 거래",
        "SecondContract": "두 번째 계약",
        "Finance": "재무 관리",
        "Announcement": "발표",
        "Assets": "자산",
        "Account": "계정"
    },
    "Login": {
        "Login": "로그인",
        "Register": "등록하다",
        "RetrievePassword": "비밀번호 찾기",
        "Type": "이메일",
        "Email": "이메일",
        "Password": "비밀번호",
        "ConfirmPassword": "비밀번호 확인",
        "VerificationCode": "확인 코드",
        "Send": "보내다",
        "PhoneNumber": "전화 번호",
        "InvitationCode": "초대 코드",
        "Agree": "읽었으며 동의합니다",
        "UserAgreement": "사용자 계약",
        "EnterEmail": "이메일을 입력하세요",
        "EnterEmailError": "올바른 이메일 형식을 입력하세요.",
        "EnterPassword": "비밀번호를 입력하세요",
        "EnterPasswordError": "비밀번호 형식은 6~20자",
        "EnterConfirmPassword": "비밀번호를 확인하세요",
        "EnterConfirmPasswordError": "두 번 입력한 비밀번호가 일치하지 않습니다.",
        "EnterEmailFirst": "이메일을 먼저 입력해주세요",
        "EnterVerificationCode": "인증 코드를 입력하세요",
        "EnterPhoneNumber": "휴대폰 번호를 입력하세요.",
        "EnterInvitationCode": "초대코드를 입력해주세요",
        "ConfirmAgreement": "이용약관을 읽고 동의해주세요",
        "Forget": "비밀번호를 잊으셨나요?"
    },
    "Home": {
        "TcTitle": "공고",
        "TopTitle1": "가상화폐 투자를 빠르게 시작하세요",
        "TopTitle2": "NeoDeal는 암호화폐를 사고팔 수 있는 가장 신뢰할 수 있고 안전한 곳입니다.",
        "EmailAddress": "이메일 주소",
        "StartUsing": "사용 시작",
        "SeeMore": "더보기",
        "MarketTrend": "시장 동향",
        "Currency": "통화",
        "LatestPrice": "최신 가격",
        "Change": "24시간 변경",
        "TradingVolume": "24시간 거래량",
        "Trend": "경향",
        "Operate": "작동하다",
        "Trade": "거래",
        "AllTradingVolume": "24시간 거래량 교환",
        "SupportedCountries": "지원되는 국가",
        "RegisterNumber": "등록된 사용자 수",
        "Box4Title": "가장 신뢰할 수 있는 암호화폐 플랫폼",
        "Box4Desc": "NeoDeal를 선택해야 하는 이유는 다음과 같습니다.",
        "Box4TabTitles": [
            "다양한 거래",
            "최소 수수료",
            "최고의 보안",
            "궁극의 사용자 경험"
        ],
        "Box4TabDescs": [
            "NeoDeal는 최고의 유동성, 풍부한 암호화폐 거래 및 자산 관리 서비스를 갖춘 주문서를 제공하여 다양한 거래 요구를 충족합니다.",
            "거래자에게 P2P 금융 시장에 대한 액세스 권한을 제공함으로써 NeoDeal는 거래 수수료가 가장 낮은 특정 암호화폐 현물 거래 쌍을 선택합니다.",
            "NeoDeal는 오프라인 저장 방식을 채택하고 개인 거래 데이터는 엔드 투 엔드 암호화를 통해 보장되며 플랫폼은 실시간으로 모니터링하여 사용자 자산의 보안을 완벽하게 보호합니다.",
            "사용자 우선, 사용하기 쉬움, 피드백 듣기, 빠른 제품 최적화, 7*24시간 실시간 온라인, 다국어 일대일 전문 서비스."
        ],
        "Box5Title": "다양한 사용자의 요구를 충족시키기 위해 지속적으로 제품 라인을 풍부하게 함",
        "Box5TabTitles": [
            "무기한 계약",
            "통화 거래",
            "두 번째 계약",
            "재무 관리"
        ],
        "Box5TabDescs": [
            "레버리지 거래는 레버리지를 이용하여 자신의 자금을 증거금으로 사용하고 플랫폼에서 N배의 자금을 원금으로 차입하여 투자하여 수익을 얻는 것입니다.",
            "특정 디지털 통화를 가격 단위로 사용하고, 다른 유형의 디지털 통화를 구매하고, 가장 좋은 가격으로 암호화폐를 빠르게 사고 팔고, 최고의 수익을 얻으세요.",
            "금융파생상품은 단기적인 시장상황을 분석하여 특정 시점에 정해진 가격으로 매수 또는 매도하고 빠른 빈도로 거래하여 차익을 얻는 거래입니다.",
            "경쟁력있는 높은 연간 수익률, 일류 자본 보장, 쉬운 수입!"
        ],
        "TradeNow": "지금 거래",
        "Box6Title1": "언제 어디서나 거래 시작",
        "Box6Title2": "APP와 WEB 모두 빠르게 거래를 열 수 있습니다.",
        "FooterItems1": [
            "약관 및 정책",
            "요금 기준",
            "위험 경고",
            "자금 세탁 방지",
            "회사 소개"
        ],
        "FooterItems2": [
            "서비스 프라이버시",
            "서비스 약관",
            "개인 정보 보호 정책",
            "백지",
            "MSB 인증"
        ],
        "FooterItems3": [
            "지원 센터",
            "초보자 가이드",
            "운영 가이드",
            "문의하기"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "통화 이름을 입력하세요.",
        "Currency": "통화",
        "LatestPrice": "최신 가격",
        "Change": "변화",
        "Highest": "최대 24시간",
        "Lowest": "최소 24시간",
        "OrderBook": "주문하다",
        "LatestRecord": "최신 거래",
        "All": "모두",
        "Bids": "구매",
        "Asks": "판매 주문",
        "Price": "가격",
        "Quantity": "수량",
        "Total": "총",
        "Time": "시간",
        "AveragePrice": "평균 가격",
        "EnterPayPass": "거래 비밀번호를 입력해주세요",
        "BuyTips": "구매하시겠습니까?",
        "type": "유형"
    },
    "PerpetualContract": {
        "OrderType": "주문 유형",
        "Market": "시장",
        "EnterQuantity": "구매수량 입력",
        "HandlingFee": "수수료",
        "AvailableBalance": "사용 가능한 잔액",
        "Lever": "지렛대",
        "Fee": "비용",
        "OpenLong": "길게 열어",
        "OpenShort": "오픈 쇼트",
        "CurrentOrders": "현재 주문",
        "OrderHistory": "주문 내역",
        "Margin": "여유",
        "CurrentPrice": "현재 가격",
        "OpeningPrice": "시가",
        "UnsettledProfit": "미결제 이익",
        "Operate": "작동하다",
        "OpenTime": "개관 시간",
        "ClosingTime": "마감 시간",
        "ClosingPrice": "종가",
        "SettlementProfit": "정산이익",
        "ClosePosition": "포지션을 청산하다",
        "Edit": "편집하다",
        "EditOrder": "주문 수정",
        "EnterCloseQuantity": "수량을 입력해주세요",
        "ConfirmOrder": "주문 확인",
        "TakeProfitPrice": "이익 가격",
        "StopPrice": "중지 가격"
    },
    "CurrencyTrading": {
        "Buy": "구입하다",
        "Sell": "팔다",
        "MyBalance": "내 균형",
        "AvailablePurchaseAmount": "구매가능금액",
        "ConvertibleAmount": "전환 가능 금액",
        "CurrentPrice": "현재 가격",
        "BuyQuantity": "수량 구매",
        "EnterBuyQuantity": "구매할 수량을 입력하세요",
        "SellQuantity": "판매 수량",
        "EnterSellQuantity": "판매할 수량을 입력하세요",
        "RequiredAmount": "필요한 금액",
        "ExchangeAmount": "교환 금액",
        "OrderHistory": "주문 내역",
        "Status": "상태",
        "Success": "성공"
    },
    "SecondContract": {
        "Buy": "구입하다",
        "OpeningQuantity": "개봉 수량",
        "EnterOpeningQuantity": "통화 금액 입력",
        "SelectQuantity": "수량 선택",
        "OpeningTime": "개장 시간",
        "minute": "분",
        "Balance": "균형",
        "Transfer": "옮기다",
        "HandlingFee": "수수료",
        "Profitability": "수익성",
        "BuyUp": "사다",
        "BuyDown": "공매도",
        "InTransaction": "거래 중",
        "Closed": "닫은",
        "OrderNumber": "주문 번호",
        "CurrentPrice": "현재 가격",
        "Direction": "방향",
        "EstimatedProfitLoss": "예상 손익",
        "Countdown": "카운트다운",
        "OpeningPrice": "시가",
        "ClosingPrice": "종가",
        "ProfitAndLoss": "이익과 손실"
    },
    "Finance": {
        "TopTitle": "돈 보물",
        "TopDesc": "고정(잠금된 재무 관리), 현재 재무 관리 및 기타 유형의 상품을 포함하여 안정적인 투자 사용자에게 최고의 투자 채널을 제공하는 이자를 버는 블록체인 자산 보유 통화, 안정적인 수입 투자 플랫폼입니다.",
        "AllProducts": "모든 상품",
        "InProgress": "진행 중",
        "AboutToStart": "곧 시작합니다",
        "ItsOver": "끝났어",
        "FinancialRecords": "재무 기록",
        "ReferenceAnnualized": "참조 연간화",
        "day": "하늘",
        "hour": "시간",
        "minute": "분",
        "second": "두번째",
        "Unlock": "터놓다",
        "RemainingPosition": "남은 위치",
        "LockedPositions": "잠긴 위치",
        "EndOfDistance": "거리의 끝",
        "JoinNow": "지금 가입하세요",
        "OurAdvantage": "우리의 장점",
        "AdvanListTitles": [
            "효율적인 수입",
            "더 짧은 주기",
            "안전하고 믿을 수 있는"
        ],
        "AdvanListDescs": [
            "다양한 전문 금융 도구 조합을 사용하여 사용자를 위한 고품질 고수익 금융 상품을 만듭니다.",
            "주기가 더 유연하고 락업이 7일로 짧습니다.",
            "전문 관리 및 위험 관리 솔루션은 자산 반환을 보호합니다."
        ],
        "JoinNum": "{joinNum}명이 가입했습니다.",
        "DailyRate": "일일 요금",
        "StartingTime": "시작 시간",
        "LockPeriod": "잠금 기간",
        "RebateMethod": "리베이트 방식",
        "RebateMethod1": "만기에 반환",
        "RebateMethod2": "일일 리베이트",
        "LockPosition": "잠금 위치",
        "ProductSpecification": "제품 사양",
        "LockedCurrency": "잠긴 통화",
        "SubscriptionAmount": "구독 금액",
        "MinAndMax": "최소 최대",
        "TotalPosition": "총 위치",
        "TradingRules": "거래 규칙",
        "BuyProductTips": "이 제품을 구독하시겠습니까?",
        "Pending": "보류 중",
        "Completed": "완전한",
        "ProductName": "상품명",
        "PurchaseAmount": "구매 금액",
        "CumulativeIncome": "누적 수입",
        "EndTime": "종료 시간"
    },
    "Assets": {
        "FinancialRecords": "재무 기록",
        "Deposit": "보증금",
        "Withdraw": "철회하다",
        "Transfer": "옮기다",
        "TotalBalance": "전체 균형",
        "Refresh": "새로 고침",
        "Currency": "통화",
        "AvailableBalance": "사용 가능한 잔액",
        "Frozen": "겨울 왕국",
        "Operate": "작동하다",
        "Type": "유형",
        "Time": "시간",
        "Quantity": "수량",
        "SelectCurrency": "통화 선택",
        "Address": "주소",
        "Notice": "알아채다",
        "DepositRecord": "예금 기록",
        "OrderNumber": "주문 번호",
        "Status": "상태",
        "Status1": "진행 중",
        "Status2": "완전한",
        "Status3": "실패하다",
        "Status4": "이상",
        "Status5": "취소 된",
        "WithdrawalAmount": "출금 금액",
        "Least": "가장 작은",
        "All": "모두",
        "WithdrawalCurrency": "출금통화",
        "WithdrawalAddress": "출금 주소",
        "HandlingFee": "수수료",
        "ReceiptAmount": "영수증 금액",
        "TransactionPassword": "거래 비밀번호",
        "SetTransactionPassword": "거래 비밀번호 설정",
        "GoogleVerificationCode": "구글 인증 코드",
        "EnterWithdrawalAmount": "출금금액을 입력해주세요",
        "EnterWithdrawalAddress": "출금주소를 입력해주세요",
        "EnterTransactionPassword": "거래 비밀번호를 입력해주세요",
        "EnterGoogleVerificationCode": "Google 인증 코드를 입력하세요.",
        "WithdrawalsRecord": "출금기록",
        "ExchangeCurrency": "환전",
        "ExchangeAmount": "교환 금액",
        "ExchangeRate": "환율",
        "Expected": "예상되는",
        "TransferRecord": "이체 기록",
        "CurrencyTransfer": "송금 통화",
        "Success": "성공",
        "EnterExchangeAmount": "환전금액을 입력해주세요"
    },
    "Account": {
        "Logout": "로그아웃",
        "PersonalCenter": "개인 센터",
        "LoginHistory": "로그인 기록",
        "Time": "시간",
        "Account": "계정",
        "LoginPassword": "로그인 비밀번호",
        "Modify": "개정하다",
        "TransactionPassword": "거래 비밀번호",
        "SetTransactionPassword": "거래 비밀번호 설정",
        "ResetTransactionPassword": "거래 비밀번호 재설정",
        "Recommend": "추천하다",
        "MobileAuth": "모바일 인증",
        "BindMailbox": "사서함 바인딩",
        "GoogleAuth": "구글 인증",
        "KYCCertification": "KYC 인증",
        "MobileAuthDesc": "보안 확인을 위한 비밀번호 찾기 및 보안 설정 수정에 사용",
        "BindMailboxDesc": "보안 확인을 위한 비밀번호 찾기 및 보안 설정 수정에 사용",
        "GoogleAuthDesc": "보안 확인을 위해 통화를 인출하는 데 사용",
        "Add": "에 추가",
        "Remove": "제거하다",
        "GoAuth": "인증하러 가기",
        "Verified": "확인",
        "UnderReview": "검토 중",
        "ChangeLoginPassword": "로그인 비밀번호 변경",
        "ChangeLoginPasswordTips": "로그인 비밀번호 변경 후 24시간 이내에는 현금 출금이 불가능하오니, 신중한 이용 부탁드립니다.",
        "OriginalPassword": "원래 암호를 입력하십시오",
        "NewPassword": "새 비밀번호를 입력하세요",
        "ConfirmPassword": "새 비밀번호를 다시 입력하세요",
        "EnterOriginalPassword": "원래 비밀번호를 입력해주세요",
        "EnterNewPassword": "새 비밀번호를 입력하세요",
        "EnterConfirmPassword": "새로운 비밀번호를 다시 입력해주세요",
        "EnterPasswordError": "비밀번호 형식은 6~20자",
        "EnterConfirmPasswordError": "두 번 입력한 비밀번호가 일치하지 않습니다.",
        "ChangeTransactionPasswordTips": "거래 비밀번호 변경 후 24시간 이내에는 현금 출금이 불가능하오니, 신중한 운영 부탁드립니다.",
        "EnterLoginPassword": "로그인 비밀번호 입력",
        "PleaseEnterLoginPassword": "비밀번호를 입력하세요",
        "AddGoogleAuth": "Google OTP 추가",
        "Step1": "1단계 'Google Authenticator' 앱 다운로드",
        "Step2": "2단계 Google Authenticator에 키 추가 및 백업",
        "Step3": "3단계 Google Validator의 6자리 인증 코드 입력",
        "Step1Desc": "iOS 사용자는 App Store에 로그인하고 'Authenticator'를 검색하여 다운로드합니다.<br/><br/> 안드로이드 사용자는 앱스토어 로그인 또는 모바일 브라우저에서 '구글인증기' 검색 후 다운로드",
        "Step2Desc": "Google Authenticator를 열고 아래 QR 코드를 스캔하거나 아래 키를 수동으로 입력하여 인증 토큰을 추가하세요.",
        "CopyKey": "복사 키",
        "EnterGoogleVerificationCode": "구글 인증코드 입력",
        "PleaseEnterGoogleVerificationCode": "Google 인증 코드를 입력하세요.",
        "RemoveGoogleAuth": "Google 검사기 제거",
        "RemoveGoogleAuthTips": "Google Validator 삭제 후 24시간 이내에는 현금 출금이 불가능하오니, 신중하게 운영하시기 바랍니다.",
        "UploadIDPhoto": "신분증 사진 업로드",
        "UploadIDPhotoTips": "전체 문서의 컬러 이미지를 업로드하십시오. 스크린샷은 허용되지 않습니다. 사진의 크기는 10M를 초과할 수 없으며 JPG 또는 PNG 형식만 가능합니다.",
        "FrontPhoto": "신분증 앞면 사진",
        "BackPhoto": "신분증 뒷면 사진",
        "FrontPhotoTips": "신분증 앞면 사진을 업로드하려면 클릭하세요.",
        "BackPhotoTips": "신분증 뒷면 사진을 업로드하려면 클릭하세요."
    },
    "FundingType": {
        "All": "모두",
        "system": "시스템 운영",
        "commission": "수수료",
        "recharge": "재충전",
        "withdraw": "철회하다",
        "withdrawFail": "출금 실패",
        "second_order": "몇 초 안에 주문하세요",
        "secondOrderWin": "두 번째 계약 승리",
        "perpetual_order": "무기한 계약 주문 보증금 + 수수료",
        "perpeTualOrderClo": "무기한 계약 체결",
        "trade": "통화 거래",
        "buyFinance": "돈을 사다",
        "financeProfit": "금융 리베이트",
        "financeMoney": "환불 교장"
    },
    "Common": {
        "Language": "언어",
        "Tips": "힌트",
        "Confirm": "확인하다",
        "Cancel": "취소",
        "Submit": "제출하다",
        "copy": "복사",
        "CopySuccessfully": "성공적으로 복사",
        "NoData": "데이터 없음",
        "Loading": "대기 중...",
        "Back": "반품",
        "Details": "세부"
    }
}
