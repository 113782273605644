<template>
    <div class="my-order">
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <el-table v-if="curIndex == 0" :data="recordList" style="width: 100%;">
            <el-table-column prop="id" :label="$t('SecondContract.OrderNumber')">
            </el-table-column>
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <span>{{scope.row.product.name}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Quantity')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.money)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.HandlingFee')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.service_fee)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.OpeningPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.oprice)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.CurrentPrice')">
                <template slot-scope="scope">
                    <span>{{curPrice(scope.row.product.id)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.Direction')">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.direction == 1 ? '#01BD8B' : '#FB474E' }">{{scope.row.direction == 1 ? $t('SecondContract.BuyUp') : $t('SecondContract.BuyDown')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.EstimatedProfitLoss')">
                <template slot-scope="scope">
                    <span v-if="scope.row.direction == 1">{{curPrice(scope.row.product.id) > parseFloat(scope.row.oprice) ? (parseFloat(scope.row.money) * parseFloat(scope.row.profit_rate) / 100).toFixed(2) : `-${parseFloat(scope.row.money)}`}}</span>
                    <span v-else>{{curPrice(scope.row.product.id) < parseFloat(scope.row.oprice) ? (parseFloat(scope.row.money) * parseFloat(scope.row.profit_rate) / 100).toFixed(2) : `-${parseFloat(scope.row.money)}`}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.Countdown')">
                <template slot-scope="scope">
                    <span>{{countdown(scope.row.timer)}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-table v-if="curIndex == 1" :data="recordList" style="width: 100%;">
            <el-table-column prop="id" :label="$t('SecondContract.OrderNumber')">
            </el-table-column>
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <span>{{scope.row.product.name}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Quantity')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.money)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.HandlingFee')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.service_fee)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="minutes" :label="$t('SecondContract.minute')">
            </el-table-column>
            <el-table-column :label="$t('SecondContract.OpeningPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.oprice)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.ClosingPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.Direction')">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.direction == 1 ? '#01BD8B' : '#FB474E' }">{{scope.row.direction == 1 ? $t('SecondContract.BuyUp') : $t('SecondContract.BuyDown')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('SecondContract.ProfitAndLoss')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.profit)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('Trade.Time')">
            </el-table-column>
        </el-table>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="9"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next, jumper"
                :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabList: [this.$t('SecondContract.InTransaction'), this.$t('SecondContract.Closed')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                timer: null
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updateSecondOrder')
            this.$bus.$on('updateSecondOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        destroyed() {
            clearInterval(this.timer)
        },
        computed: {
            productList() {
                return this.$store.state.productList
            },
            
            curPrice() {
                return id => {
                    let price = 0
                    this.productList.forEach(item => {
                        if(id == item.id) {
                            price = parseFloat(item.price.close)
                        }
                    })
                    
                    return price
                }
            },
            
            countdown() {
                return s => {
                    return this.calCountdown(s)
                }
            }
        },
        methods: {
            calCountdown(second) {
                //得到小时 格式化成前缀加零的样式
                let h = parseInt(second / 60 / 60)
                h = h < 10 ? '0' + h : h
                //得到分钟 格式化成前缀加零的样式
                let m = parseInt(second / 60 % 60)
                m = m < 10 ? '0' + m : m
                //得到秒 格式化成前缀加零的样式
                let s = parseInt(second % 60)
                s = s < 10 ? '0' + s : s
            
                return `${h}:${m}:${s}`
            },
            
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                clearInterval(this.timer)
                this.$api.secondOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                    if(this.curIndex == 0) {
                        this.timer = setInterval(() => {
                            if(this.recordList.length > 0) {
                                this.recordList.map((item,index) => {
                                    if(item.timer <= 0) {
                                        this.recordList.splice(index,1)
                                    } else {
                                        item.timer--
                                    }
                                })
                            } else {
                                clearInterval(this.timer)
                            }
                        },1000)
                    } else {
                        clearInterval(this.timer)
                    }
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 40px;
                line-height: 40px;
                box-sizing: border-box;
                padding: 0 16px;
                font-size: 14px;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 12px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 12px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 14px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 20px 32px 20px 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>