
const d3 = require('d3')
import Chart from './draw'
export default function (symbol,data){
    // const svg = document.createElement('svg');
    const svgtarget = d3.selectAll(symbol).append('svg')
    svgtarget.attr('xmlns', 'http://www.w3.org/2000/svg')
    var chart = new Chart({
        target: svgtarget,
        width: 130,
        height: 58,
        // linearGradient: [{ //初始化渐变色
        //     id: 'green',
        //     colors: ['green',d3.rgb(210,210,210,0.065), d3.rgb(230,230,230,0.01)]
        // }],
        // areaFillId: '#green',
        // stroke:  d3.rgb(0,0,0,.3),//初始化折线颜色
        strokeWidth: 2, //初始化折线宽度 默认1
    })
    chart.render([...data])
    return svgtarget;
}
