<template>
    <div class="h5-container">
		<BACKH5 :title="title" />
        <div v-html="content" class="content"></div>
    </div>
</template>

<script>
    export default {
		components: {
		    BACKH5: () => import('@/components/BackH5.vue')
		},
        data() {
            return {
				title: '',
                content: ''
            }
        },
        created() {
			this.title = this.$route.query.name || ''
            let slug = this.$route.query.slug
            if(slug) {
                this.getSetting(slug)
            }
        },
        methods: {
            getSetting(slug) {
                this.$api.setting_lang({
                    slug
                },300000).then(res => {
                    if(res.data) {
                        this.content = res.data
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .content {
            width: 100%;
			box-sizing: border-box;
			padding: 1.2rem 1.2rem 1.6rem;
            word-wrap: break-word;
        }
    }
</style>