<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Account.ChangeLoginPassword')" />
        <div class="form-box">
            <div class="warning-tips">
                <i class="el-icon-warning"></i>
                {{$t('Account.ChangeLoginPasswordTips')}}
            </div>
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="originalPassword">
                    <el-input v-model="form.originalPassword" show-password :placeholder="$t('Account.OriginalPassword')"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="form.password" show-password :placeholder="$t('Account.NewPassword')"></el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                    <el-input v-model="form.confirmPassword" show-password :placeholder="$t('Account.ConfirmPassword')"></el-input>
                </el-form-item>
            </el-form>
            <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Common.Confirm')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            const checkPassword = (rule, value, callback) => {
                if (!value) {
                    callback(new Error(this.$t('Account.EnterConfirmPassword')))
                } else if (value != this.form.password) {
                    callback(new Error(this.$t('Account.EnterConfirmPasswordError')))
                } else {
                    callback()
                }
            }
            return {
                form: {
                    originalPassword: '',
                    password: '',
                    confirmPassword: ''
                },
                rules: {
                    originalPassword: [{
                            required: true,
                            message: this.$t('Account.EnterOriginalPassword'),
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: this.$t('Account.EnterPasswordError'),
                            trigger: 'blur'
                        }
                    ],
                    password: [{
                            required: true,
                            message: this.$t('Account.EnterNewPassword'),
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: this.$t('Account.EnterPasswordError'),
                            trigger: 'blur'
                        }
                    ],
                    confirmPassword: [{
                        validator: checkPassword,
                        trigger: 'blur'
                    }]
                },
                loading: false
            }
        },
        methods: {
            submit() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.reset_auth(this.form).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            setTimeout(() => {
                                this.$router.go(-1)
                            },1000)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .form-box {
            width: 100%;
            box-sizing: border-box;
            padding: 2.4rem 1.6rem 3rem;
            margin-top: 1.2rem;
            background-color: #FFFFFF;

            .warning-tips {
                display: flex;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 1.2rem 1.6rem;
                margin-bottom: 2.4rem;
                border-radius: 4px;
                font-size: 1.3rem;
                color: #f28e3d;
                word-wrap: break-word;
                background-color: #fffae6;

                .el-icon-warning {
                    margin-right: 5px;
                    font-size: 1.7rem;
                    color: #f28e3d;
                    white-space: nowrap;
                }
            }

            /deep/ .el-form-item {
                margin-bottom: 2.4rem;
            }

            /deep/ .el-input__inner {
                height: 4.2rem;
                line-height: 4.2rem;
                padding: 0 1.6rem;
                border-radius: 4px;
                border: 1px solid #D3D3D3;
                font-size: 1.3rem;
                color: #000000;
                background-color: #FFFFFF;
            }

            .submit-btn {
                width: 100%;
                height: 4.8rem;
                box-sizing: border-box;
                margin: 2.6rem 0 0;
                padding: 0;
                border: 0;
                border-radius: 4px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                background-color: #5466EF;
                cursor: pointer;
            }
        }
    }
</style>
